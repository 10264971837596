import { useI18n, useVoucher } from "@jog/react-components";
import { styled, useTheme } from "@jog/theming";
import classNames from "classnames";
import { AlertBox, Button, LogoLoader } from "components";
import { observer } from "mobx-react";
import { useStores } from "stores";
const DeleteVoucherButton = styled.span `
    &:before {
        font-family: ${(props) => props.theme.fontFamily.icomoonJC};
        font-size: 14px;
        font-weight: 400;
        content: "\\e902";
    }
`;
export const DiscountCodeForm = observer(() => {
    var _a, _b;
    const theme = useTheme();
    const { $t } = useI18n();
    const { cartStore, voucherStore } = useStores();
    const { inputVal, setInputVal, subtotalPrice, applyVoucher, removeVoucher, voucher, isGiftcardCode, isLoading, invalidVoucher, } = useVoucher({ cartStore, voucherStore });
    return (<>
            {isLoading && <LogoLoader />}
            <div className={classNames("mb-7.5 flex md:mb-0", voucher ? "max-w-[400px]" : "")}>
                <div className="relative flex w-full">
                    <input className="h-9.5 w-full border border-white border-x-white border-b-gray-shuttle border-t-white px-1.25 py-0 text-left text-sm outline-none focus:border focus:border-gray-shuttle" placeholder={$t("Cart code")} onChange={(event) => setInputVal(event.target.value)} value={voucher ? voucher.code : inputVal} disabled={!!voucher}/>
                    {voucher && (<DeleteVoucherButton className="absolute right-0 top-px flex h-11 w-11.5 cursor-pointer items-center justify-center border-r border-r-gray-light bg-white" onClick={() => removeVoucher()}/>)}
                </div>
                {!voucher && (<Button className="hover:bg-white" bgColor={theme.color.black} color={theme.color.white} paddingMobile="10px 20px" disabled={!!voucher || String(inputVal).length < 2} onClick={() => applyVoucher()}>
                        {$t("Activate")}
                    </Button>)}
            </div>
            {/* if voucher value is more than the total price notify the user */}
            {((_a = voucherStore.voucherData) === null || _a === void 0 ? void 0 : _a.voucherType) === "MONEY_DISCOUNT" &&
            ((_b = voucherStore.voucherData) === null || _b === void 0 ? void 0 : _b.value) > subtotalPrice && (<div className="mt-1.25 text-base text-gray-main">
                        {$t("The value of the voucher is higher than the shopping basket. you can proceed to checkout or continue shopping!")}
                    </div>)}
            {voucher && (<div className="mt-5 mb-10 w-full text-base">
                    <div className="text-gray-main">{$t("Promotions received")}</div>
                    <div>
                        {$t("Webshop voucher")} -{" "}
                        <span className="font-primary italic text-red-main">{voucher.description}</span>
                    </div>
                </div>)}
            {voucherStore.voucherErrorMessage && !isGiftcardCode && (<div className="mt-1.25 text-base text-red-main">{$t("This voucher is invalid")}</div>)}
            {isGiftcardCode && (<div className="mt-1.25 text-base text-gray-main">
                    {$t("You accidentally entered a gift card code. Use this during checkout.")}
                </div>)}
            {invalidVoucher && (<div>
                    <AlertBox isGrayInfo textTransform="none" text={`${$t("Cart code")} ${invalidVoucher} ${$t("is no longer valid")}`}/>
                </div>)}
        </>);
});
