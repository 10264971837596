import { Link, useCartPageSidebar, useI18n, useLastLister } from "@jog/react-components";
import { useTheme } from "@jog/theming";
import { AlertBox, Button, HR, LogoLoader, Price, TextDropdown, TotalPrice } from "components";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { CartShippingCosts } from "./CartShippingCosts";
import { DiscountCodeForm } from "./DiscountCodeForm";
const CartPageSidebar = ({ prismicContent }) => {
    var _a;
    const theme = useTheme();
    const { $t } = useI18n();
    const { cartStore, gtmStore } = useStores();
    const { showDiscount, cartPrices, checkForChangesInCart, isLoading, isMinOrderAmountNotReached } = useCartPageSidebar({
        prismic: {
            minOrderAmount: prismicContent.minimum_order_amount,
            showDiscountField: prismicContent.show_discount_field,
        },
        store: { cartStore, gtmStore },
    });
    const { path: lastLister } = useLastLister();
    const cartViewBody = (_a = cartStore === null || cartStore === void 0 ? void 0 : cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body;
    return (<div className="ml-0 min-w-[300px] max-w-full flex-1 bg-white py-10 px-5 xl:ml-4 xl:min-w-[400px] xl:max-w-[400px]">
            {isLoading && <LogoLoader />}
            <div className="px-1.75">
                <div>
                    <div className="mb-5 flex justify-between">
                        <h4 className="text-[17px] font-medium capitalize text-black">{$t("Subtotal")}:</h4>
                        {cartViewBody && <Price fontSize="16px" price={cartPrices.subTotalPrice}/>}
                    </div>

                    {cartPrices.totalDiscount > 0 && (<div className="mb-5 flex justify-between">
                            <h4 className="text-[17px] capitalize text-black">{$t("Discount")}:</h4>
                            <Price fontSize="16px" color={theme.color.mainRed} price={cartPrices.totalDiscount}/>
                        </div>)}

                    <HR css={{ borderTop: theme.color.lightSandGrey }}/>
                    <TotalPrice cartViewBody={cartViewBody}/>
                </div>

                <div>
                    <TextDropdown text={$t("Enter a discount code")} marginBot="10px" displayStatus={showDiscount ? "block" : "none"}>
                        <DiscountCodeForm />
                    </TextDropdown>
                </div>
            </div>

            <div>
                <div className="hidden px-1.75 md:flex">
                    <Link href={lastLister || "/"} title="">
                        <TextDropdown text={$t("Continue shopping")} isLeftArrow={true}/>
                    </Link>
                </div>
                <div>
                    <div>
                        {isMinOrderAmountNotReached && (<AlertBox isMinOrderMessage text={`${$t(`You can continue with your order when the minumim amount of`)} € ${prismicContent.minimum_order_amount} ${$t("is reached")}`}/>)}
                        <Button disabled={cartStore.disableCheckoutButton || isMinOrderAmountNotReached} onClick={checkForChangesInCart} className="w-full cursor-pointer text-center hover:bg-white" bgColor={theme.color.black} color={theme.color.white}>
                            <span className="relative w-full whitespace-nowrap px-5">{$t("To Order")}</span>
                        </Button>
                    </div>
                    <CartShippingCosts prismicContent={prismicContent}/>
                </div>
            </div>
        </div>);
};
export default observer(CartPageSidebar);
