import { Section } from "@/components/Section/Section";
import { useI18n } from "@jog/react-components";
import { observer } from "mobx-react";
import { useStores } from "stores";
const AccountProfile = () => {
    const { userStore: { userData }, } = useStores();
    const { $t } = useI18n();
    const mappedUserData = [{ fieldName: $t("E-mail address"), fieldValue: userData === null || userData === void 0 ? void 0 : userData.email }];
    return (<Section id="email-section" title={$t("E-mail address")} updatePageLink={"/my-account/update-email"}>
            <ul>
                {mappedUserData.map((item) => (<li key={item.fieldName}>
                        {/* <h3>{item.fieldName}</h3> */}
                        <p>{item.fieldValue}</p>
                    </li>))}
            </ul>
        </Section>);
};
export default observer(AccountProfile);
