import { styled } from "@jog/theming";
// BUG: props.theme.fontSize.itemLabel was not available in the JS version
const AccountPurchaseDetailsWrapper = styled.div `
    section > div {
        margin-bottom: 20px;
    }
    h3 {
        font-size: ${(props) => props.theme.fontSize.itemLabel};
        margin-bottom: 0;
    }
    p {
        font-size: 14px;
    }
    .shop-details {
        p {
            margin-bottom: 0;
        }
    }
    .shipping-details {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        p {
            margin-bottom: 0;
        }
        div:last-child {
            margin-left: 30px;
        }
    }
    .vouchers-details {
        li {
            list-style: none;
            p {
                margin-bottom: 0;
            }
        }
    }
    .order-details {
        h3 {
            margin-bottom: 20px;
        }
    }
    .order-total {
        width: 100%;
        @media screen and (min-width: 426px) {
            width: 50%;
            align-self: flex-end;
        }
        div {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            .discount-value {
                color: ${(props) => props.theme.color.mainRed};
            }
        }
    }
`;
export default AccountPurchaseDetailsWrapper;
