import { getOrdersDetail } from "@/apis/return/get-orders-detail";
import { AlertBox } from "@/components/AlertBox/AlertBox";
import { GRichText } from "@/components/RichText/RichText";
import { ContentLoader, ReturnThankyouBase } from "@jog/react-components";
import { returnOrderDetail } from "@jog/shared";
import React from "react";
export const ReturnThankyouContent = React.memo(ReturnThankyouBase({
    AlertBox,
    ContentLoader,
    RichText: GRichText,
    getReturnOrder: returnOrderDetail,
    getOrdersDetail,
}));
