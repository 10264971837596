import { Price } from "@/components";
import { useLineItemPrice } from "@jog/react-components";
import { useTheme } from "@jog/theming";
export const PriceContainer = ({ data }) => {
    const theme = useTheme();
    const { finalPrice, firstPrice } = useLineItemPrice(data);
    if (finalPrice) {
        return (<div className="flex">
                <div className="text-left lg:text-right">
                    <Price color={theme.color.gray} fontSize="12px" isLabelShow={false} isOldPrice price={firstPrice}/>
                </div>
                <div className="text-left font-primary font-normal lg:text-right">
                    <Price color={theme.color.mainRed} fontSize="16px" marginLeft="10px" price={finalPrice}/>
                </div>
            </div>);
    }
    return <Price fontSize="16px" price={firstPrice}/>;
};
export const UnavailableMessage = ({ message, bg = "red", color = "white", }) => {
    return (<div className="mt-2.5 flex px-4 py-3" style={{ backgroundColor: bg, color }}>
            <div className="mr-2 shrink-0">
                <svg className="fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path d="M512 97.52381c228.912762 0 414.47619 185.563429 414.47619 414.47619s-185.563429 414.47619-414.47619 414.47619S97.52381 740.912762 97.52381 512 283.087238 97.52381 512 97.52381z m0 73.142857C323.486476 170.666667 170.666667 323.486476 170.666667 512s152.81981 341.333333 341.333333 341.333333 341.333333-152.81981 341.333333-341.333333S700.513524 170.666667 512 170.666667z m36.571429 268.190476v292.571428h-73.142858V438.857143h73.142858z m0-121.904762v73.142857h-73.142858v-73.142857h73.142858z"></path>
                </svg>
            </div>
            <div className="flex-1">{message}</div>
        </div>);
};
