import { useI18n } from "@jog/react-components";
import { getCountryNameByCode } from "@jog/shared";
import React, { useMemo } from "react";
const CountrySwitcher = ({ items }) => {
    const country = process.env.NEXT_PUBLIC_COUNTRY;
    // For .com → country selector France (most visitors on .com are coming from France)
    const defaultCountryCode = country === "US" ? "FR" : country;
    const defaultValue = useMemo(() => {
        var _a;
        return ((_a = items.find((field) => field.text.toUpperCase() === defaultCountryCode)) === null || _a === void 0 ? void 0 : _a.url) ||
            "https://www.wearegarcia.nl/";
    }, [defaultCountryCode, items]);
    const { $t } = useI18n();
    return (<form className="inline-block">
            <select onChange={(e) => (window.location.href = e.target.value)} id="countries" name="countries" style={{
            backgroundImage: 'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMC8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvVFIvMjAwMS9SRUMtU1ZHLTIwMDEwOTA0L0RURC9zdmcxMC5kdGQnPjxzdmcgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PHBvbHlsaW5lIGZpbGw9Im5vbmUiIHBvaW50cz0iMjEsOC41IDEyLDE3LjUgMyw4LjUgIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+")',
        }} className="min-w-full cursor-pointer appearance-none rounded-none border-none bg-right bg-no-repeat pr-7 text-black" defaultValue={defaultValue}>
                {items.map((field, index) => (<option key={index} value={field.url}>
                        {$t(getCountryNameByCode(field.text.toUpperCase()))}
                    </option>))}
            </select>
        </form>);
};
export default React.memo(CountrySwitcher);
