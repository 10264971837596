import { asText } from "@prismicio/helpers";
import { PrismicRichText } from "@prismicio/react";
const CartShippingCostsRichText = ({ field }) => (<PrismicRichText field={field} components={{
        heading1: ({ children }) => <h1 className="font-primary text-sm font-medium uppercase">{children}</h1>,
        heading2: ({ children }) => <h2 className="font-primary text-sm font-medium uppercase">{children}</h2>,
        heading3: ({ children }) => <h3 className="font-primary text-sm font-medium uppercase">{children}</h3>,
        heading4: ({ children }) => <h4 className="font-primary text-sm font-medium uppercase">{children}</h4>,
        heading5: ({ children }) => <h5 className="font-primary text-sm font-medium uppercase">{children}</h5>,
        paragraph: ({ children, node, text }) => {
            if (!node.spans.length && !text)
                return (<p>
                            <br />
                        </p>);
            return <p className="mb-0.5 text-sm">{children}</p>;
        },
        list: ({ children }) => <ul className="list-none pl-0">{children}</ul>,
        listItem: ({ children }) => <li className="text-sm">{children}</li>,
    }}/>);
export const CartShippingCosts = ({ prismicContent }) => {
    const slices = [...((prismicContent === null || prismicContent === void 0 ? void 0 : prismicContent.body) || [])].filter((i) => i.slice_type === "shipping___return");
    return (<>
            {slices.map((body, index) => {
            return (<div className="mt-10 mb-5 text-left" key={index}>
                        <div>
                            <h2 className="font-primary text-sm font-medium uppercase">
                                {body.primary.title && asText(body.primary.title)}
                            </h2>
                            <ul>
                                {body.items.map((item, itemIndex) => {
                    return (<li key={`${index}_${itemIndex}`}>
                                            <CartShippingCostsRichText field={item.method}/>
                                            <CartShippingCostsRichText field={item.costs}/>
                                        </li>);
                })}
                            </ul>
                        </div>
                    </div>);
        })}
        </>);
};
