import { ContentLoader, useI18n } from "@jog/react-components";
import { PurchaseListSection, Section } from "components";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { uid } from "react-uid";
import { useStores } from "stores";
import AccountPurchaseListWrapper from "./AccountPurchaseListWrapper";
const AccountPurchaseList = () => {
    const { purchaseStore } = useStores();
    const [purchaseList, setPurchaseList] = useState([]);
    useEffect(() => {
        var _a;
        if (((_a = purchaseStore.purchaseData) === null || _a === void 0 ? void 0 : _a.length) == 0) {
            purchaseStore.getPurchaseData().then((lst) => setPurchaseList(lst));
        }
        else {
            setPurchaseList(purchaseStore.purchaseData.slice());
        }
    }, [purchaseStore]);
    const { $t } = useI18n();
    return (<AccountPurchaseListWrapper>
            {(purchaseList === null || purchaseList === void 0 ? void 0 : purchaseList.length) == 0 && purchaseStore.isLoadingPurchase ? (Array(16)
            .fill(undefined)
            .map((e, index) => <ContentLoader width="100%" height="40px" key={uid(index)}/>)) : (purchaseList === null || purchaseList === void 0 ? void 0 : purchaseList.length) > 0 ? (<PurchaseListSection purchaseList={purchaseList}/>) : (<Section>
                    <p>{$t("You don't have any registered purchases at the moment.")}</p>
                </Section>)}
        </AccountPurchaseListWrapper>);
};
export default observer(AccountPurchaseList);
