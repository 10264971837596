import { AddressItem } from "@/components/AddressItem/AddressItem";
import { Section } from "@/components/Section/Section";
import { useI18n } from "@jog/react-components";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { AddressList } from "../AddressList/AddressList";
function AccountAddress() {
    var _a, _b, _c, _d, _e;
    const { userStore } = useStores();
    const { $t } = useI18n();
    return (<>
            <Section id="address-section" title={$t("Contact address")} updatePageLink={`/my-account/edit-address/?addressid=${(_a = userStore.userAddress) === null || _a === void 0 ? void 0 : _a.uid}&type=billingAddress`}>
                <AddressItem key={(_b = userStore.userAddress) === null || _b === void 0 ? void 0 : _b.uid} title={`${(_c = userStore.userAddress) === null || _c === void 0 ? void 0 : _c.name} ${((_d = userStore.userAddress) === null || _d === void 0 ? void 0 : _d.insertion) || ""} ${(_e = userStore.userAddress) === null || _e === void 0 ? void 0 : _e.familyName}`} address={userStore === null || userStore === void 0 ? void 0 : userStore.userAddress} showUpdate={false}/>
            </Section>
            <Section title={$t("Delivery addresses")}>
                <AddressList userOtherAddresses={userStore.userOtherAddresses}/>
            </Section>
        </>);
}
export default observer(AccountAddress);
