import { AlertBox } from "@/components/AlertBox/AlertBox";
import { buckarooGetOrderView } from "@/lib/jog";
import { useFasletOrderTracking } from "@faslet/react-widget";
import { useAdpage } from "@jog/react-components";
import { gtmAdpagePurchase, localStorage, navigate } from "@jog/shared";
import { asText } from "@prismicio/helpers";
import { Container, LogoLoader } from "components";
import { observer } from "mobx-react";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useStores } from "stores";
import LineItems from "./LineItems";
import PaymentFooter from "./PaymentFooter";
import PaymentInfoHeader from "./PaymentInfoHeader";
import SovendusContainer from "./SovendusContainer";
import UserInfo from "./UserInfo";
export const PaymentContent = observer(({ payment, sovendus }) => {
    var _a, _b;
    const { gtmStore } = useStores();
    const [order, setOrder] = useState(null);
    const [error, setError] = useState(false);
    const getOrderView = async (orderId) => {
        const response = await buckarooGetOrderView(orderId);
        if (response && response.statusCode === 200) {
            setOrder(response.body);
            localStorage.removeItem("createdCart");
        }
        else {
            setError(true);
        }
    };
    const shopId = process.env.NEXT_PUBLIC_FASLET_SHOP_ID;
    const router = useRouter();
    useEffect(() => {
        if (!router.isReady)
            return;
        const orderId = router.query.orderId;
        if (orderId) {
            getOrderView(orderId);
        }
        else {
            navigate(`/`);
        }
    }, [router]);
    const adpage = useAdpage();
    useEffect(() => {
        if (!order)
            return;
        gtmStore.gtmTransactionData(order);
        gtmStore.gtmTrustedShops(order);
        gtmStore.gtmEnhancedConversion(order);
        if (!adpage.marketing)
            return;
        gtmAdpagePurchase(order, adpage.marketing);
    }, [order, gtmStore, adpage]);
    const productsInOrder = useMemo(() => {
        var _a;
        return (((_a = order === null || order === void 0 ? void 0 : order.lineItems) === null || _a === void 0 ? void 0 : _a.map((item) => {
            return {
                productId: `${item.articleName}_${item.colorCode}`.replaceAll(" ", "_"),
                variantId: item.sku,
                productName: item.name["en"],
                variantName: item.length ? `${item.size}/${item.length}` : item.size,
                priceTimesQuantity: item.prices.totalPrice.centAmount / Math.pow(10, item.prices.totalPrice.fractionDigits),
                quantity: item.quantity,
                sku: item.sku,
            };
        })) || []);
    }, [order]);
    useFasletOrderTracking(shopId, (order === null || order === void 0 ? void 0 : order.orderNumber) || "", "Complete", productsInOrder);
    return (<Container className="mb-17.5">
                <div className="bg-white px-5 pt-0 pb-5 lg:px-0 lg:pt-15 lg:pb-0">
                    {order ? (<>
                            <PaymentInfoHeader name={asText(payment.name)} paymentInfoTexts={payment.payment_explanation_text} orderNumber={order.orderNumber}/>
                            <div className="py-5 px-0" id="sovendus-container-1"/>
                            <UserInfo order={order} paymentIcons={(_b = (_a = payment.body) === null || _a === void 0 ? void 0 : _a.at(0)) === null || _b === void 0 ? void 0 : _b.items}/>
                            <LineItems order={order}/>
                            <PaymentFooter order={order}/>
                            {sovendus.enable_sovendus && (<SovendusContainer order={order} trafficSourceNumber={sovendus.traffic_source_number} trafficMediumNumber={sovendus.traffic_medium_number}/>)}
                        </>) : (<LogoLoader />)}
                    {error && <AlertBox isError={true} text="Something went wrong"/>}
                </div>
            </Container>);
});
