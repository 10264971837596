import { useWishlist } from "@/lib/store/wishlist";
import { useStores } from "@/stores";
import { useI18n } from "@jog/react-components";
import { gtmAddUserId, gtmAdpageEmail, sessionController } from "@jog/shared";
import { useTheme } from "@jog/theming";
import classNames from "classnames";
import { ArrowText, Button, Input, LogoLoader } from "components";
import { getSession, signIn } from "next-auth/react";
import { useCallback, useState } from "react";
const LoginForm = ({ emailForCheck, isCheckout, toggleModal, goToStepOne, inputType = "main", otherOptionsShow = true, hideModal, onLogin, }) => {
    const { $t } = useI18n();
    const theme = useTheme();
    const [password, setPassword] = useState("");
    const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
    const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState(true);
    const [isLoginIng, setLoginStatus] = useState(false);
    const mergeWishlist = useWishlist.use.mergeWishlist();
    const myWishlist = useWishlist.use.myWishlist();
    const getWishlist = useWishlist.use.getWishlist();
    const onPasswordFieldChange = (event) => {
        setPassword(event.target.value);
        setIsLoginBtnDisabled(!event.target.value || event.target.value.length <= 4);
    };
    const { cartStore, userStore } = useStores();
    const onLoginThroughWishlistModal = useCallback(async () => {
        var _a, _b, _c, _d, _e, _f;
        if (((_c = (_b = (_a = cartStore === null || cartStore === void 0 ? void 0 : cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.lineItems) === null || _c === void 0 ? void 0 : _c.length) && ((_e = (_d = cartStore === null || cartStore === void 0 ? void 0 : cartStore.cartView) === null || _d === void 0 ? void 0 : _d.body) === null || _e === void 0 ? void 0 : _e.id) !== userStore.userCartId) {
            // anonymous cart has items in it, and it's different that the one user has in his acc
            const assignSuccess = await cartStore.assignCartFromAnonymousUser();
            if (assignSuccess) {
                await userStore.getUserData(false);
            }
            else {
                await userStore.getUserData(true);
                await cartStore.getCartView(cartStore.cartView.body.id);
            }
        }
        else {
            if (userStore.userCartId) {
                // user has previous cart, assign it
                await cartStore.getCartView(userStore.userCartId);
            }
        }
        // localStorage.setItem('name', userStore.userData.name)
        // if (!localStorage.getItem('user-id') && userStore?.userData?.id) {
        //   localStorage.setItem('user-id', userStore.userData.id)
        //   gtmStore.gtmAddUserId(userStore.userData.id)
        // }
        if ((_f = userStore.userData) === null || _f === void 0 ? void 0 : _f.id) {
            localStorage.setItem("user-id", userStore.userData.id);
            gtmAddUserId(userStore.userData.id);
        }
    }, [cartStore, userStore]);
    const handleLoginUser = useCallback(async (event) => {
        try {
            event.preventDefault();
            setLoginStatus(true);
            setIsPasswordInvalid(false);
            const res = await signIn("credentials", {
                redirect: false,
                email: String(emailForCheck).toLowerCase(),
                password: password,
            }).then(async (res) => {
                if (res.ok) {
                    const session = await getSession();
                    sessionController().set(session);
                }
                return res;
            });
            gtmAdpageEmail(emailForCheck.toLowerCase());
            setLoginStatus(false);
            if (res.ok) {
                await (onLogin === null || onLogin === void 0 ? void 0 : onLogin());
                const wishlistId = localStorage.getItem("wishlistId");
                wishlistId ? await mergeWishlist() : await myWishlist();
                if (hideModal) {
                    hideModal();
                    await getWishlist();
                    await onLoginThroughWishlistModal();
                }
            }
            if (res.error) {
                setIsPasswordInvalid(true);
            }
        }
        catch {
            setIsPasswordInvalid(false);
            setLoginStatus(false);
        }
    }, [
        emailForCheck,
        getWishlist,
        hideModal,
        mergeWishlist,
        myWishlist,
        onLogin,
        onLoginThroughWishlistModal,
        password,
    ]);
    return (<div className="text-base">
            {isLoginIng && <LogoLoader />}
            {!isCheckout && inputType === "main" && <h1 className="mb-6.25">{$t("Log in / Register")}</h1>}

            <form className="mb-7.5">
                <div className={classNames("mb-7.5 flex items-center lg:flex-row", inputType === "secondary" ? "flex-row" : "flex-col items-start")}>
                    <div className={classNames("basis-full", inputType === "secondary"
            ? "hidden shrink-0 lg:block lg:basis-3/12"
            : "w-full lg:basis-2/12")}>
                        <label htmlFor="username">{$t("E-mail address")} *</label>
                    </div>
                    <div className={classNames("w-full basis-full", {
            "lg:basis-7/12": !isCheckout && inputType === "main",
            "lg:basis-10/12": isCheckout && inputType === "main",
            "w-full": inputType === "secondary",
        })}>
                        <div className={classNames("flex items-center bg-gray-bg", {
            "border pl-3 text-11 lg:text-sm": inputType === "secondary",
        })}>
                            {inputType === "secondary" && (<div className="block shrink-0 lg:hidden">
                                    <label htmlFor="username">{$t("E-mail address")} *</label>
                                </div>)}
                            <Input className={classNames("!h-11.5 w-full py-2.75 px-3 !text-left", inputType === "secondary" ? "!border-none" : "border border-gray-medium")} id="username" name="username" type="email" value={emailForCheck} disabled={true}/>
                        </div>
                    </div>
                </div>
                <div className={classNames("mb-7.5 flex items-center lg:flex-row", inputType === "secondary" ? "flex-row" : "flex-col items-start")}>
                    <div className={classNames("flex w-full basis-full items-center", inputType === "secondary" ? "hidden shrink-0  sm:basis-3/12 lg:block" : "sm:basis-2/12")}>
                        <label htmlFor="password">{$t("Password")} *</label>
                    </div>
                    <div className={classNames("w-full basis-full", {
            "sm:basis-7/12": !isCheckout && inputType === "main",
            "sm:basis-10/12": isCheckout && inputType === "main",
            "basis-full": inputType === "secondary",
        })}>
                        <div className={classNames("flex items-center", {
            "border pl-3 text-11 lg:pl-0 lg:text-sm": inputType === "secondary",
        })}>
                            {inputType === "secondary" && (<div className="block shrink-0 lg:hidden">
                                    <label htmlFor="password">{$t("Password")} *</label>
                                </div>)}
                            <Input className={classNames("!h-11.5 w-full py-2.75 px-3 !text-left", inputType === "secondary" ? "!border-none" : "border border-gray-medium")} id="password" name="password" type="password" onInput={onPasswordFieldChange} defaultValue={password}/>
                        </div>

                        {isPasswordInvalid && <div className="password-error">{$t("Invalid password")}</div>}
                    </div>
                </div>
                <div className="flex">
                    <div className={classNames("flex w-full basis-full lg:justify-end", isCheckout
            ? "justify-end sm:basis-full"
            : inputType === "secondary"
                ? "justify-start sm:basis-full"
                : "justify-end sm:basis-9/12")}>
                        <Button className="hover:bg-auto" bgColor={theme.color.black} color={theme.color.white} fontWeight="600" disabled={isLoginBtnDisabled} onClick={handleLoginUser}>
                            {$t("next one")}
                        </Button>
                    </div>
                </div>
            </form>

            {otherOptionsShow && (<>
                    <div className="mb-2.75" onClick={() => toggleModal()}>
                        <ArrowText text={$t("Forgot your password?")} marginBot="0"/>
                    </div>
                    <div>
                        <span onClick={goToStepOne} css={{ display: "inline-block" }}>
                            <ArrowText text={$t("Log in with a different account")} marginBot="10px"/>
                        </span>
                    </div>
                </>)}
        </div>);
};
export default LoginForm;
