import { replaceTextInRichText } from "@jog/shared";
import { useMemo } from "react";
export const usePromoRules = (promoRuleData, products = []) => {
    const locale = process.env.NEXT_PUBLIC_LOCALE;
    const lineItemsWithAlert = useMemo(() => {
        const rulesObj = {};
        promoRuleData === null || promoRuleData === void 0 ? void 0 : promoRuleData.forEach((item) => {
            const meetRuleProducts = products
                .filter((product) => { var _a; return ((_a = product.promotionDescription) === null || _a === void 0 ? void 0 : _a[locale]) === item.campaign_name; })
                .reduce((pre, cur) => pre + cur.quantity, 0);
            const displayNumber = item.campaign_number && meetRuleProducts % item.campaign_number
                ? item.campaign_number - (meetRuleProducts % item.campaign_number)
                : 0;
            meetRuleProducts &&
                Object.assign(rulesObj, {
                    [item.campaign_name]: {
                        ...item,
                        alert_text: replaceTextInRichText(item.alert_text, "extra_num", `${displayNumber}`),
                        isAlertShow: !!displayNumber,
                    },
                });
        });
        return products.map((item) => {
            var _a;
            const itemWithAlert = {
                ...item,
                alertInfo: [],
                alertColor: "",
                alertBgColor: "",
            };
            const rule = rulesObj[((_a = item.promotionDescription) === null || _a === void 0 ? void 0 : _a[locale]) || ""];
            if (rule && rule.isAlertShow && rule.campaign_number && !!(item.quantity % rule.campaign_number)) {
                rule.isAlertShow = false;
                itemWithAlert.alertInfo = rule.alert_text;
                itemWithAlert.alertColor = rule.text_color;
                itemWithAlert.alertBgColor = rule.bg_color;
            }
            return itemWithAlert;
        });
    }, [products, locale, promoRuleData]);
    return {
        lineItemsWithAlert,
    };
};
