import { GRichText } from "@/components/RichText/RichText";
import { isObjectEmpty, parseVoucherTags } from "@jog/shared";
import { Section } from "components";
import { useEffect } from "react";
import AccountVoucherDetailsWrapper from "./AccountVoucherDetailsWrapper";
const AccountVoucherDetails = ({ voucherData }) => {
    useEffect(() => {
        // parsedLegalNotice = parseVoucherTags(voucherData, legalNoticeHTML);
        if (!isObjectEmpty(voucherData)) {
            // Replace Voucher Description html content with parsed tags
            const descriptionNode = document.getElementsByClassName("description");
            const parsedDescriptionHtml = parseVoucherTags(voucherData, descriptionNode[0].innerHTML);
            descriptionNode[0].innerHTML = parsedDescriptionHtml;
            // Replace Legal Notice html content with parsed tags
            const legalNoticeNode = document.getElementsByClassName("legal-notice");
            const parsedLegalNoticeHTML = parseVoucherTags(voucherData, legalNoticeNode[0].innerHTML);
            legalNoticeNode[0].innerHTML = parsedLegalNoticeHTML;
            // Replace Redeem Online html content with parsed tags
            const redeemOnlineNode = document.getElementsByClassName("redeem-online");
            const parsedRedeemOnlineHTML = parseVoucherTags(voucherData, redeemOnlineNode[0].innerHTML);
            redeemOnlineNode[0].innerHTML = parsedRedeemOnlineHTML;
            // Replace Voucher Header html content with parsed tags
            const voucherHeaderNode = document.getElementsByClassName("voucher-header");
            const parsedVoucherHeaderHtml = parseVoucherTags(voucherData, voucherHeaderNode[0].innerHTML);
            voucherHeaderNode[0].innerHTML = parsedVoucherHeaderHtml;
        }
    }, [voucherData]);
    return (<Section>
            <AccountVoucherDetailsWrapper>
                <div className={voucherData.isRedeemed ? "redeemed" : ""}>
                    <picture>
                        <source srcSet={voucherData.desktopImageUrl} media="(min-width: 481px)"/>
                        <img src={voucherData.imageUrl} alt={voucherData.title}/>
                    </picture>
                    <h2 className="voucher-header">{voucherData.title}</h2>
                    <p>
                        <span className="expiration-date"> Verloopt op {voucherData.expirationDate}</span>
                    </p>
                    <div className="voucher-section description">
                        <GRichText field={voucherData.description}/>
                    </div>
                    <div className="voucher-section redeem-in-store">
                        <GRichText field={voucherData.redeemInStore}/>
                    </div>
                    <div className="voucher-section redeem-online">
                        <GRichText field={voucherData.redeemOnline}/>
                    </div>
                    <hr />
                    <div className="voucher-section legal-notice">
                        {voucherData.legalNotice && <GRichText field={voucherData.legalNotice}/>}
                    </div>
                </div>
            </AccountVoucherDetailsWrapper>
        </Section>);
};
export default AccountVoucherDetails;
