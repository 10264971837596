import { useCallback } from "react";
import { usePending } from "../components";
export const useCheckDutchAddress = ({ getDutchAddress, }) => {
    const { setPending } = usePending();
    const getAddress = useCallback(async (values) => {
        var _a;
        if (((_a = values.values.country) === null || _a === void 0 ? void 0 : _a.toUpperCase()) !== "NL")
            return;
        if (!values.errors.postalCode && !values.errors.houseNumber) {
            setPending(true);
            try {
                const returnedAddress = await getDutchAddress(values.values.postalCode || "", values.values.houseNumber || "", values.values.addition || "");
                if (returnedAddress) {
                    values.setFieldValue("city", returnedAddress.body.city || "");
                    values.setFieldValue("streetName", returnedAddress.body.streetname || "");
                }
            }
            catch {
            }
            finally {
                setPending(false);
            }
        }
    }, [getDutchAddress, setPending]);
    return { getAddress };
};
