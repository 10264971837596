import { Button } from "@/components/Button/Button";
import { getDutchAddress } from "@/lib/jog";
import { useStores } from "@/stores";
import { FormikInputField, useCheckDutchAddress, useI18n, useSignUpForm } from "@jog/react-components";
import { getListCountry, handlePreSubmitValidation } from "@jog/shared";
import { FormikFormWrapper, styled, useTheme } from "@jog/theming";
import { AlertBox, FormikSelectField } from "components";
import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { uid } from "react-uid";
const SignUpFormWrapper = styled.div `
    font-size: 14px;

    label {
        align-self: center;
    }

    .Error {
        text-align: left;
        white-space: nowrap;
        margin-bottom: 0;
    }

    .radio-wrapper {
        display: flex;

        input[type="radio"] {
            width: 20px;
            margin-right: 10px;
            margin-top: 10px;
            font-size: 16px;
        }

        label {
            margin: 0;
        }
    }
`;
const SignUpForm = () => {
    const { userStore } = useStores();
    const router = useRouter();
    const { initialValues, onSubmit, validateSignUp } = useSignUpForm({
        userStore,
        onSuccess: useCallback(() => router.push(`/login`), [router]),
    });
    const { getAddress } = useCheckDutchAddress({ getDutchAddress });
    const { $t } = useI18n();
    const theme = useTheme();
    const countryOptions = getListCountry($t("CountryList"));
    return (<SignUpFormWrapper>
            <Formik initialValues={initialValues} validate={validateSignUp} onSubmit={onSubmit}>
                {(values) => {
            var _a;
            return (<Form>
                        {userStore.isRegistrationError && (<AlertBox isError={true} text={$t("Something went wrong please try again")}/>)}
                        <FormikFormWrapper>
                            <Row>
                                <Col sm="12">
                                    {/* COUNTRY */}
                                    <Row css={{ marginBottom: "20px" }}>
                                        <FormikSelectField id="country" label={`${$t("Country")} *`} name="country" smRight="4">
                                            <option disabled value="select">
                                                {$t("Select")}
                                            </option>
                                            {(_a = Object.keys(countryOptions)) === null || _a === void 0 ? void 0 : _a.map((key) => (<option key={uid(key)} value={key}>
                                                    {countryOptions[key]}
                                                </option>))}
                                        </FormikSelectField>
                                        <Col sm={{
                    span: "4",
                    offset: "4",
                }}>
                                            <ErrorMessage name="country" component="div" className="Error" css={{
                    textAlign: "right",
                }}/>
                                        </Col>
                                    </Row>

                                    {/* Gender */}
                                    <Row css={{ marginBottom: "20px" }}>
                                        <FormikSelectField id="gender" label={`${$t("Sex")} *`} name="gender" smRight="4">
                                            <option disabled value="select">
                                                {$t("Select")}
                                            </option>
                                            <option value="MALE">{$t("Male")}</option>
                                            <option value="FEMALE">{$t("Female")}</option>
                                        </FormikSelectField>
                                        <Col sm={{
                    span: "4",
                    offset: "4",
                }}>
                                            <ErrorMessage name="gender" component="div" className="Error" css={{
                    textAlign: "right",
                }}/>
                                        </Col>
                                    </Row>

                                    {/* First Name */}
                                    <Row css={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                }}>
                                        <FormikInputField id="firstName" fieldName="firstName" label={`${$t("First Name")} *`}/>
                                    </Row>

                                    {/* Tussen */}
                                    <Row css={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                }}>
                                        <FormikInputField id="middleName" fieldName="middleName" smRight="2" label={$t("insertion")}/>
                                    </Row>

                                    {/* Last Name */}
                                    <Row css={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                }}>
                                        <FormikInputField id="lastName" fieldName="lastName" label={`${$t("Last Name")} *`}/>
                                    </Row>

                                    {/* Postcode */}
                                    <Row css={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                }}>
                                        <FormikInputField id="postalCode" fieldName="postalCode" smRight="2" label={`${$t("Postcode")} *`} inputBlur={() => getAddress(values)}/>
                                    </Row>

                                    <Row css={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                }}>
                                        {/* Street num */}
                                        <FormikInputField id="houseNumber" fieldName="houseNumber" inputMode="numeric" smLeft="4" smRight="2" label={`${$t("Street number")} *`} inputBlur={() => getAddress(values)}/>
                                        {/* Toev */}
                                        <FormikInputField id="addition" fieldName="addition" smLeft="2" smRight="2" leftOffset="2" label={$t("Toevoeging")} css={{
                    paddingLeft: "30px",
                }}/>
                                    </Row>

                                    {/* Str Name */}
                                    <Row css={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                }}>
                                        <FormikInputField id="streetName" fieldName="streetName" label={`${$t("Street name")} *`}/>
                                    </Row>

                                    {/* City */}
                                    <Row css={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                }}>
                                        <FormikInputField id="city" fieldName="city" label={`${$t("City")} *`}/>
                                    </Row>

                                    {/* Tel num */}
                                    <Row css={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                }}>
                                        <FormikInputField id="telNum" fieldName="telNum" type="tel" label={$t("Telephone number")}/>
                                    </Row>

                                    {/* Email addr */}
                                    <Row css={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                }}>
                                        <FormikInputField id="email" fieldName="email" isInputDisabled={true} label={`${$t("E-mail address")} *`}/>
                                    </Row>

                                    {/* Password */}
                                    <Row css={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "30px",
                }}>
                                        <FormikInputField id="password" fieldName="password" type="password" autocomplete="new-password" label={`${$t("Password")} *`}/>
                                    </Row>

                                    {/* Confirm Password */}
                                    <Row css={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                }}>
                                        <FormikInputField id="confirmPassword" fieldName="confirmPassword" type="password" autocomplete="new-password" label={`${$t("Confirm password")} *`}/>
                                    </Row>

                                    <Row>
                                        <Col sm="12" css={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "20px",
                }}>
                                            <Button className="hover:bg-auto" bgColor={theme.color.black} color={theme.color.white} fontWeight="600" type="submit" onClick={() => handlePreSubmitValidation(values.errors)}>
                                                {$t("Next step")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </FormikFormWrapper>
                    </Form>);
        }}
            </Formik>
        </SignUpFormWrapper>);
};
export default observer(SignUpForm);
