export const getStoreURLById = (storeId) => {
    switch (storeId) {
        case "705":
            return {
                url: "https://www.wearegarcia.de/",
                name: "wearegarcia.de",
            };
        case "706":
            return {
                url: "https://www.wearegarcia.nl/",
                name: "wearegarcia.nl",
            };
        case "707":
            return {
                url: "https://www.wearegarcia.be/",
                name: "wearegarcia.be",
            };
        case "708":
            return {
                url: "https://www.wearegarcia.fr/",
                name: "wearegarcia.fr",
            };
        case "709":
            return {
                url: "https://www.wearegarcia.at/",
                name: "wearegarcia.at",
            };
        case "710":
            return {
                url: "https://www.wearegarcia.es/",
                name: "wearegarcia.es",
            };
        case "711":
            return {
                url: "https://www.wearegarcia.lu/",
                name: "wearegarcia.lu",
            };
        case "712":
            return {
                url: "https://www.wearegarcia.com/",
                name: "wearegarcia.com",
            };
        default:
            return {
                url: "https://www.wearegarcia.nl/",
                name: "wearegarcia.nl",
            };
    }
};
export const getStoreURLByCountryCode = (countryCode) => {
    switch (countryCode) {
        case "DE":
            return "https://www.wearegarcia.de/";
        case "NL":
            return "https://www.wearegarcia.nl/";
        case "BE":
            return "https://www.wearegarcia.be/";
        case "FR":
            return "https://www.wearegarcia.fr/";
        case "AT":
            return "https://www.wearegarcia.at/";
        case "ES":
            return "https://www.wearegarcia.es/";
        case "LU":
            return "https://www.wearegarcia.lu/";
        case "US":
            return "https://www.wearegarcia.com/";
        default:
            return "https://www.wearegarcia.nl/";
    }
};
export const getAllCountriesWithLanguages = () => [
    { name: "Germany - Deutsch", url: "https://www.wearegarcia.de", countryCode: "DE" },
    // { name: "Germany - English", url: "https://www.wearegarcia.de/en" },
    { name: "Netherlands - Dutch", url: "https://www.wearegarcia.nl", countryCode: "NL" },
    // { name: "Netherlands - English", url: "https://www.wearegarcia.nl/en" },
    { name: "Belgium - Dutch", url: "https://www.wearegarcia.be", countryCode: "BE" },
    // { name: "Belgium - English", url: "https://www.wearegarcia.be/en" },
    { name: "Luxembourg - English", url: "https://www.wearegarcia.com", countryCode: "LU" },
    { name: "Spain - English", url: "https://www.wearegarcia.com", countryCode: "ES" },
    { name: "Austria - English", url: "https://www.wearegarcia.com", countryCode: "AT" },
    { name: "France - English", url: "https://www.wearegarcia.com", countryCode: "FR" },
];
export const wrongCountryErrorMessage = (userCountryCode) => {
    switch (userCountryCode) {
        case "DE":
            return `Von Deutschland aus bestellen? <a href="https://www.wearegarcia.de">Klicke hier</a>, um zum deutschen Webshop zu gelangen.`;
        case "NL":
            return 'Bestellen vanuit Nederland? <a href="https://www.wearegarcia.nl">Klik hier</a> om naar de Nederlandse webshop te gaan.';
        case "BE":
            return 'Bestellen vanuit Belgie? <a href="https://www.wearegarcia.be">Klik hier</a> om naar de Belgische webshop te gaan.';
        case "FR":
        case "AT":
        case "ES":
        case "LU":
            return 'Ordering from Austria/France/Luxemburg/Spain? Please <a href="https://www.wearegarcia.com">visit</a> our <a href="https://www.wearegarcia.com">wearegarcia.com</a> webshop.';
        default:
            return null;
    }
};
