import { convertCentsToEuros, gtmCartPageBasketOverview, localStorage } from "@jog/shared";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePending } from "../components";
export const useCartPageSidebar = ({ prismic: { minOrderAmount, showDiscountField }, store, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [isLoading, setIsLoading] = useState(false);
    const { cartStore, gtmStore } = store;
    const isMinOrderAmountNotReached = useMemo(() => {
        var _a, _b, _c;
        return !!(minOrderAmount &&
            Number(convertCentsToEuros((_c = (_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.total) === null || _c === void 0 ? void 0 : _c.centAmount)) < Number(minOrderAmount));
    }, [(_c = (_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.total) === null || _c === void 0 ? void 0 : _c.centAmount, minOrderAmount]);
    useEffect(() => {
        cartStore.recalculateCart().then(() => {
            var _a, _b, _c;
            ((_c = (_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.cartMessage) === null || _c === void 0 ? void 0 : _c.length) && cartStore.validateCart();
        });
    }, [cartStore]);
    const router = useRouter();
    const session = useSession();
    const isUserLoggedIn = useMemo(() => session.status == "authenticated", [session]);
    const checkForChangesInCart = useCallback(async () => {
        var _a, _b, _c, _d, _e;
        setIsLoading(true);
        await cartStore.recalculateCart();
        if ((_c = (_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.cartMessage) === null || _c === void 0 ? void 0 : _c.length) {
            await cartStore.validateCart();
        }
        else {
            gtmStore.gtmFromCartToCheckout(isUserLoggedIn, ((_e = (_d = cartStore.cartView) === null || _d === void 0 ? void 0 : _d.body) === null || _e === void 0 ? void 0 : _e.lineItems) || []);
            cartStore.clearActionList();
            await router.push("/checkout");
        }
        setIsLoading(false);
    }, [cartStore, gtmStore, isUserLoggedIn, router]);
    const cartPrices = cartStore.getCartPrices;
    const voucher = ((_f = (_e = (_d = cartStore.cartView) === null || _d === void 0 ? void 0 : _d.body) === null || _e === void 0 ? void 0 : _e.vouchers) === null || _f === void 0 ? void 0 : _f.length) !== 0 ? (_g = cartStore.cartView) === null || _g === void 0 ? void 0 : _g.body.vouchers[0] : null;
    const showDiscount = showDiscountField || !!voucher;
    return {
        isMinOrderAmountNotReached,
        checkForChangesInCart,
        isLoading,
        isUserLoggedIn,
        cartPrices,
        voucher,
        showDiscount,
    };
};
export const useVoucher = ({ cartStore, voucherStore }) => {
    var _a, _b, _c, _d, _e, _f;
    const subtotalPrice = ((_c = (_b = (_a = cartStore === null || cartStore === void 0 ? void 0 : cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.subtotal) === null || _c === void 0 ? void 0 : _c.centAmount) || 0;
    const cartId = useMemo(() => { var _a, _b; return (_b = (_a = cartStore === null || cartStore === void 0 ? void 0 : cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.id; }, [(_e = (_d = cartStore === null || cartStore === void 0 ? void 0 : cartStore.cartView) === null || _d === void 0 ? void 0 : _d.body) === null || _e === void 0 ? void 0 : _e.id]);
    const voucher = useMemo(() => { var _a, _b; return (_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body.vouchers) === null || _b === void 0 ? void 0 : _b[0]; }, [(_f = cartStore.cartView) === null || _f === void 0 ? void 0 : _f.body.vouchers]);
    const [inputVal, setInputVal] = useState("");
    const [isGiftcardCode, setIsGiftcardCode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [invalidVoucher, setInvalidVoucher] = useState(null);
    const applyVoucher = useCallback(async () => {
        if (!cartId)
            return;
        setIsLoading(true);
        setIsGiftcardCode(false);
        // if it starts with BR notify the user its giftcard code
        const isGiftcardCode = inputVal.toUpperCase().startsWith("BR");
        isGiftcardCode ? setIsGiftcardCode(true) : await voucherStore.applyVoucher(inputVal, cartId);
        voucherStore.voucherErrorMessage && setInputVal("");
        setIsLoading(false);
    }, [cartId, inputVal, voucherStore]);
    const removeVoucher = useCallback(async () => {
        if (!(voucher === null || voucher === void 0 ? void 0 : voucher.code))
            return;
        if (!cartId)
            return;
        setIsLoading(true);
        await voucherStore.removeVoucher(voucher.code, cartId);
        setIsLoading(false);
    }, [cartId, voucher === null || voucher === void 0 ? void 0 : voucher.code, voucherStore]);
    const removeInvalidVoucher = useCallback(async () => {
        const invalidVoucher = localStorage.getItem("invalidVoucher");
        if (!(invalidVoucher === null || invalidVoucher === void 0 ? void 0 : invalidVoucher.code))
            return;
        if (!cartId)
            return;
        setInvalidVoucher(invalidVoucher);
        await cartStore.getCartView(cartId);
        setIsLoading(true);
        await voucherStore.removeVoucher(invalidVoucher.code, cartId);
        setIsLoading(false);
        localStorage.removeItem("invalidVoucher");
    }, [cartId, cartStore, voucherStore]);
    useEffect(() => {
        if (localStorage.getItem("invalidVoucher")) {
            removeInvalidVoucher();
        }
    }, [removeInvalidVoucher]);
    return {
        inputVal,
        setInputVal,
        subtotalPrice,
        applyVoucher,
        removeVoucher,
        voucher,
        isGiftcardCode,
        isLoading,
        invalidVoucher,
    };
};
export const useCart = ({ cartStore }) => {
    const { setPending } = usePending();
    useEffect(() => {
        var _a, _b;
        cartStore.isCartViewLoaded && gtmCartPageBasketOverview(((_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.lineItems) || []);
    }, [cartStore.cartView, cartStore.isCartViewLoaded]);
    const recalculate = useCallback(async () => {
        var _a, _b, _c;
        await cartStore.cleanCosts();
        ((_c = (_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.cartMessage) === null || _c === void 0 ? void 0 : _c.length) && (await cartStore.validateCart());
    }, [cartStore]);
    useEffect(() => {
        setPending(true);
        recalculate().finally(() => {
            setPending(false);
        });
    }, [recalculate, setPending]);
};
