import { ClientOnly, I18n, useI18n, usePromoRules } from "@jog/react-components";
import { gtmAdpageCart } from "@jog/shared";
import { asText } from "@prismicio/helpers";
import { AlertBox, Container, LogoLoader } from "components";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStores } from "stores";
import { CartItem } from "./CartItem/CartItem";
import { UnavailableMessage } from "./CartItem/common";
import CartPageSidebar from "./CartPageSidebar";
const CartContent = ({ prismicContent, promoRuleData, }) => {
    var _a, _b, _c, _d;
    const [isLoadingCart, setLoadingCart] = useState(true);
    // const [isShippingCostReset, setShippingCostReset] = useState(false)
    const { cartStore, gtmStore } = useStores();
    const { $t } = useI18n();
    useEffect(() => {
        var _a;
        const { isCartViewLoaded, cartView } = cartStore;
        if (isCartViewLoaded && ((_a = cartView === null || cartView === void 0 ? void 0 : cartView.body) === null || _a === void 0 ? void 0 : _a.lineItems)) {
            // CartView is not empty
            gtmStore.getCartPageBasketOverview(cartView);
            gtmAdpageCart(cartView.body);
        }
    }, [cartStore, gtmStore]);
    const { lineItemsWithAlert } = usePromoRules(promoRuleData || [], ((_b = (_a = cartStore === null || cartStore === void 0 ? void 0 : cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.lineItems) || []);
    const setShippingCostToZero = useCallback(async () => {
        // fetch shipping methods and costs
        await cartStore.getShippingMethods();
        const shippingMethod = cartStore.shippingMethods.filter((e) => e.key.startsWith("dummy"))[0];
        const shippingMethodId = shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.id;
        // set shipping cost to 0
        await cartStore.setShippingCost(shippingMethodId);
        // remove payment costs
        await cartStore.removePaymentCost();
    }, [cartStore]);
    const removeCostsOnMount = useCallback(async () => {
        var _a, _b, _c, _d, _e, _f, _g;
        // if there are shipping costs remove them, same for payment costs
        ((_d = (_c = (_b = (_a = cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.shippingCosts) === null || _c === void 0 ? void 0 : _c.costs) === null || _d === void 0 ? void 0 : _d.centAmount) > 0 && (await setShippingCostToZero());
        // only payment cost are applied remove them
        ((_g = (_f = (_e = cartStore.cartView) === null || _e === void 0 ? void 0 : _e.body) === null || _f === void 0 ? void 0 : _f.paymentCosts) === null || _g === void 0 ? void 0 : _g.centAmount) && (await cartStore.removePaymentCost());
    }, [cartStore, setShippingCostToZero]);
    useEffect(() => {
        removeCostsOnMount().finally(() => {
            setLoadingCart(false);
        });
    }, [removeCostsOnMount]);
    const { isCartViewLoaded, cartView, actionList, deleteProductLoading, addProductFetching } = cartStore;
    const isRemoveInvalidCode = useMemo(() => !!actionList.find(({ action }) => action.action === "removeDiscountCode"), [actionList]);
    const unavailableLineItems = useMemo(() => actionList.filter(({ action }) => action.action !== "removeDiscountCode" &&
        (action.action !== "changeLineItemQuantity" || action.quantity === 0)), [actionList]);
    return (<div className="bg-grey-beige pb-5 pt-0 lg:pt-10">
            <Container className="mt-2 mb-16 flex flex-wrap xl:!flex-nowrap">
                {(deleteProductLoading || addProductFetching || isLoadingCart) && <LogoLoader />}
                <ClientOnly>
                    {isCartViewLoaded && ((_d = (_c = cartView === null || cartView === void 0 ? void 0 : cartView.body) === null || _c === void 0 ? void 0 : _c.lineItems) === null || _d === void 0 ? void 0 : _d.length) ? (<>
                            <div className="grow bg-white p-5 lg:py-7.5 lg:px-11.5">
                                <div>
                                    <h1 className="mt-2.75 mb-3.25 text-xl font-medium uppercase text-black">
                                        <I18n t="SHOPPING BASKET"/>
                                    </h1>
                                    {prismicContent.show_message && (<div className="mb-6 pr-2.5 text-xs text-black">
                                            {asText(prismicContent.free_shipping_message)}
                                        </div>)}
                                </div>
                                <div>
                                    {isRemoveInvalidCode && (<AlertBox isError text={$t("your voucher is removed because it is invalid")}/>)}
                                    <div className="border-t border-t-gray-lightSand">
                                        {lineItemsWithAlert.map((item) => (<CartItem data={item} key={item.id} quantityIsChanged={!!actionList.find((action) => action.action.action === "changeLineItemQuantity" &&
                    item.id === action.snapshot.id)}/>))}
                                    </div>
                                    {unavailableLineItems.length > 0 && (<div className="border-t border-t-gray-lightSand">
                                            <UnavailableMessage message={prismicContent.unavailable_article_message} bg={prismicContent.unavailable_article_bg_color} color={prismicContent.unavailable_article_text_color}/>
                                            {unavailableLineItems.map(({ snapshot }) => (<CartItem data={snapshot} key={snapshot.id} disabled/>))}
                                        </div>)}
                                </div>
                            </div>
                            <CartPageSidebar prismicContent={prismicContent}/>
                        </>) : (<div className="w-full bg-white p-5 lg:py-7.5 lg:px-11.5">
                            <div className="empty-cart">
                                <h2 className="text-[25px] capitalize text-black">
                                    <I18n t="YOUR SHOPPING BASKET IS STILL EMPTY"/>
                                </h2>
                                <h4 className="text-sm capitalize text-black">
                                    <I18n t="Go shopping! :-)"/>
                                </h4>
                            </div>
                        </div>)}
                </ClientOnly>
            </Container>
        </div>);
};
export default observer(CartContent);
