import { FasletWidget as FasletWidgetFC } from "@faslet/react-widget";
import { getGtmIdFromCT, typedBoolean } from "@jog/shared";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ClientOnly } from "../../ClientOnly";
import { useI18n } from "../../hooks";
import { Ruler } from "../Icon/Ruler";
export const FasletWidgetInWishlist = ({ pdpData, fasletAvailable, addToCart, setFasletAvailable, className }) => {
    // Get the product variants and colors
    const { $t } = useI18n();
    const [btnLabel, setBtnLabel] = useState("");
    const shopId = process.env.NEXT_PUBLIC_FASLET_SHOP_ID;
    const productIdForFaslet = useMemo(() => getGtmIdFromCT(pdpData.attributes), [pdpData]);
    const variants = useMemo(() => {
        var _a;
        const tempVariants = ((_a = pdpData === null || pdpData === void 0 ? void 0 : pdpData.sizes) === null || _a === void 0 ? void 0 : _a.map((waistItem) => {
            return waistItem.lengthSizes
                .map((lengthItem) => {
                if (!lengthItem.sku)
                    return;
                return {
                    variantId: lengthItem.sku,
                    sizeLabel: lengthItem.lengthSize
                        ? `${waistItem.waistSize}/${lengthItem.lengthSize}`
                        : `${waistItem.waistSize}`,
                    inStock: lengthItem.isOnStock,
                    sku: lengthItem.sku,
                    colorId: ``,
                    price: pdpData.discountedPrice || pdpData.originalPrice,
                };
            })
                .filter(typedBoolean);
        })) || [];
        return tempVariants.flat();
    }, [pdpData.discountedPrice, pdpData.originalPrice, pdpData === null || pdpData === void 0 ? void 0 : pdpData.sizes]);
    const addProductToCart = useCallback(async (id) => {
        const product = variants.find((variant) => variant.variantId === id);
        const productSku = (product === null || product === void 0 ? void 0 : product.sku) || "";
        await addToCart(productSku);
    }, [addToCart, variants]);
    useEffect(() => {
        window._faslet = {
            onButtonShow: () => {
                setFasletAvailable(true);
            },
            onButtonHidden: () => {
                setFasletAvailable(false);
            },
        };
    }, [setFasletAvailable]);
    // Render the widget
    return (<ClientOnly>
            {shopId && productIdForFaslet ? (<div id="faslet-button" className="faslet-container">
                    <FasletWidgetFC productId={productIdForFaslet} shopId={shopId} brandId={(pdpData === null || pdpData === void 0 ? void 0 : pdpData.brand) || ""} productImageUrl={(pdpData === null || pdpData === void 0 ? void 0 : pdpData.image) || ""} productName={pdpData.productName[process.env.NEXT_PUBLIC_LOCALE] || pdpData.productName.en} variants={variants} onAddToCart={addProductToCart} onResult={({ label }) => {
                setBtnLabel(label);
            }}/>
                    {/* custom button text */}
                    {fasletAvailable ? (<span className={classNames("mt-3.5 mb-1.5 inline-block cursor-pointer gap-1 border-none bg-white py-3 px-2 leading-none lg:rounded-sm lg:border lg:border-solid lg:border-black", className)} id="faslet-button-text" onClick={() => {
                    var _a, _b;
                    (_b = (_a = window === null || window === void 0 ? void 0 : window._faslet) === null || _a === void 0 ? void 0 : _a.openWidget) === null || _b === void 0 ? void 0 : _b.call(_a);
                }}>
                            {btnLabel ? (<>
                                    <span>{$t("Your size is")}</span>
                                    <span className="ml-2 rounded border border-gray-silver px-2 py-1">{btnLabel}</span>
                                </>) : (<span className="flex items-center">
                                    <Ruler />
                                    <span className="ml-2">{$t("Measure Modal Description")}</span>
                                </span>)}
                        </span>) : null}
                </div>) : null}
        </ClientOnly>);
};
