import { useI18n } from "@jog/react-components";
import { convertCentsToEuros } from "@jog/shared";
import { useEffect } from "react";
const SovendusContainer = ({ order, trafficSourceNumber, trafficMediumNumber }) => {
    const { $t } = useI18n();
    useEffect(() => {
        var _a, _b, _c;
        if (trafficSourceNumber && trafficMediumNumber) {
            if (typeof window !== "undefined") {
                window.sovIframes = window.sovIframes || [];
                window.sovIframes.push({
                    trafficSourceNumber,
                    trafficMediumNumber,
                    sessionId: "",
                    timestamp: Date.now(),
                    orderId: order === null || order === void 0 ? void 0 : order.orderNumber,
                    orderValue: convertCentsToEuros(((_a = order === null || order === void 0 ? void 0 : order.subtotal) === null || _a === void 0 ? void 0 : _a.centAmount) - (order === null || order === void 0 ? void 0 : order.totalDiscount.centAmount)),
                    orderCurrency: "EUR",
                    usedCouponCode: ((_c = (_b = order === null || order === void 0 ? void 0 : order.vouchers) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.code) || "",
                    iframeContainerId: "sovendus-container-1",
                });
                window.sovConsumer = window.sovConsumer || {};
                window.sovConsumer = {
                    consumerSalutation: "",
                    consumerFirstName: order === null || order === void 0 ? void 0 : order.billingAddress.firstName,
                    consumerLastName: order === null || order === void 0 ? void 0 : order.billingAddress.lastName,
                    consumerEmail: order === null || order === void 0 ? void 0 : order.billingAddress.email,
                };
            }
            const sovDomain = window.location.protocol + "\x2F\x2F" + "api.sovendus.com";
            const sovJsFile = sovDomain + "\x2Fsovabo\x2Fcommon\x2Fjs\x2FflexibleIframe.js";
            const s = document.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = sovJsFile;
            document.body.appendChild(s);
        }
    }, [order, trafficMediumNumber, trafficSourceNumber]);
    if (trafficSourceNumber && trafficMediumNumber) {
        return <></>;
    }
    return <div>{$t("Error: Could not get Sovendus configuration from Prismic")}</div>;
};
export default SovendusContainer;
