import { useStores } from "@/stores";
import { gtmAddUserId } from "@jog/shared";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
const MyAccountContext = createContext({
    isAccountUpdated: false,
    setIsAccountUpdated: () => { },
    accountAddUserAddress: () => Promise.resolve(),
    accountUpdateUserAddress: () => Promise.resolve(),
    accountDeleteUserAddress: () => Promise.resolve(),
    changePassword: () => Promise.resolve(),
    updateUser: () => Promise.resolve(),
    changeEmailAddress: () => Promise.resolve(),
});
const useBrowserPermissions = () => {
    const { userStore } = useStores();
    const getBrowserPermissions = useCallback(async () => {
        await userStore.getBrowserPermissions();
        if (userStore.isLocationEnabled) {
            await userStore.getLocation();
        }
    }, [userStore]);
    useEffect(() => {
        getBrowserPermissions();
    }, [getBrowserPermissions]);
};
const useUserData = () => {
    const { userStore, cartStore, voucherStore } = useStores();
    return useCallback(async () => {
        var _a, _b, _c, _d, _e, _f, _g;
        await userStore.getUserData(false);
        await userStore.getUserAddresses();
        if ((_a = userStore.userData) === null || _a === void 0 ? void 0 : _a.loyaltycardnumber) {
            await voucherStore.getUserVouchers(userStore.userData.loyaltycardnumber);
        }
        if (((_d = (_c = (_b = cartStore === null || cartStore === void 0 ? void 0 : cartStore.cartView) === null || _b === void 0 ? void 0 : _b.body) === null || _c === void 0 ? void 0 : _c.lineItems) === null || _d === void 0 ? void 0 : _d.length) && ((_f = (_e = cartStore === null || cartStore === void 0 ? void 0 : cartStore.cartView) === null || _e === void 0 ? void 0 : _e.body) === null || _f === void 0 ? void 0 : _f.id) !== userStore.userCartId) {
            const assignSuccess = await cartStore.assignCartFromAnonymousUser();
            if (assignSuccess) {
                await userStore.getUserData(false);
            }
            else {
                await userStore.getUserData(true);
                await cartStore.getCartView(cartStore.cartView.body.id);
            }
        }
        else {
            await userStore.getMyCart();
            if (userStore.userCartId) {
                // user has previous cart, assign it
                await cartStore.getCartView(userStore.userCartId);
            }
        }
        // localStorage.setItem('name', userStore.userData.name)
        // if (!localStorage.getItem('user-id') && userStore?.userData?.id) {
        //   localStorage.setItem('user-id', userStore.userData.id)
        //   gtmStore.gtmAddUserId(userStore.userData.id)
        // }
        if ((_g = userStore.userData) === null || _g === void 0 ? void 0 : _g.id) {
            localStorage.setItem("user-id", userStore.userData.id);
            gtmAddUserId(userStore.userData.id);
        }
    }, [cartStore, userStore, voucherStore]);
};
export const MyAccountProvider = ({ children }) => {
    var _a;
    const [isAccountUpdated, setIsAccountUpdated] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const timer = useRef(null);
    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
            timer.current && clearTimeout(timer.current);
        };
    }, []);
    const accountUpdated = useCallback(() => {
        isMounted && setIsAccountUpdated(true);
        timer.current = setTimeout(() => {
            isMounted && setIsAccountUpdated(false);
        }, 10000);
    }, [isMounted]);
    const { userStore } = useStores();
    useBrowserPermissions();
    const updateUserData = useUserData();
    useEffect(() => {
        var _a;
        if (isAccountUpdated || !((_a = userStore.userData) === null || _a === void 0 ? void 0 : _a.name)) {
            updateUserData();
        }
    }, [isAccountUpdated, updateUserData, (_a = userStore.userData) === null || _a === void 0 ? void 0 : _a.name]);
    const accountAddUserAddress = useCallback(async (values) => {
        await userStore.accountAddUserAddress(values).then(accountUpdated);
    }, [accountUpdated, userStore]);
    const accountUpdateUserAddress = useCallback(async (values, uid) => {
        await userStore.accountUpdateUserAddress(values, uid).then(accountUpdated);
    }, [accountUpdated, userStore]);
    const accountDeleteUserAddress = useCallback(async (addressId) => {
        await userStore.accountDeleteUserAddress(addressId).then(accountUpdated);
    }, [accountUpdated, userStore]);
    const changePassword = useCallback(async (previousPassword, newPassword) => {
        await userStore.changePassword(previousPassword, newPassword).then(accountUpdated);
    }, [accountUpdated, userStore]);
    const updateUser = useCallback(async (gender, firstName, middleName, lastName, birthDate, phoneNumber) => {
        await userStore
            .updateUser(gender, firstName, middleName, lastName, birthDate, phoneNumber)
            .then(accountUpdated);
    }, [accountUpdated, userStore]);
    const changeEmailAddress = useCallback(async (email, password) => {
        await userStore.changeEmailAddress(email, password).then(accountUpdated);
    }, [accountUpdated, userStore]);
    const session = useSession();
    const router = useRouter();
    useEffect(() => {
        session.status !== "authenticated" && router.isReady && router.push(`/login`);
    }, [router, session.status]);
    const value = useMemo(() => ({
        isAccountUpdated,
        setIsAccountUpdated,
        accountAddUserAddress,
        accountUpdateUserAddress,
        accountDeleteUserAddress,
        changePassword,
        updateUser,
        changeEmailAddress,
    }), [
        accountAddUserAddress,
        accountDeleteUserAddress,
        accountUpdateUserAddress,
        changeEmailAddress,
        changePassword,
        isAccountUpdated,
        updateUser,
    ]);
    return <MyAccountContext.Provider value={value}>{children}</MyAccountContext.Provider>;
};
export const useMyAccountData = () => useContext(MyAccountContext);
