import { I18n, Link } from "@jog/react-components";
import React from "react";
function FixedNavigationItem({ link, label, NavIcon, iconWidth }) {
    return (<Link href={link}>
            <div>
                <div className="flex h-8.5 items-center justify-center">
                    <NavIcon width={iconWidth}/>
                </div>

                <div className="uppercase text-black">
                    <I18n t={label}/>
                </div>
            </div>
        </Link>);
}
export default React.memo(FixedNavigationItem);
