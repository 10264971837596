import { checkLength, nameValidation, navigate, onlyDigitsMaxLength, parsePhoneNumberByCountryCode, passwordValidation, phoneValidation, postCodeValidation, } from "@jog/shared";
import { useCallback, useMemo } from "react";
import { usePending } from "../components";
import { useI18n } from "./useI18n";
export const useSignUpForm = ({ userStore, onSuccess, }) => {
    const { $t } = useI18n();
    const { setPending } = usePending();
    const onSubmit = useCallback(async (values) => {
        try {
            setPending(true);
            await userStore.createNewUser({
                ...values,
                telNum: parsePhoneNumberByCountryCode(values.telNum, values.country),
            });
            window.scrollTo({ top: 0, behavior: "smooth" });
            if (!userStore.isRegistrationError) {
                await onSuccess();
                navigate(`/login`);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setPending(false);
        }
    }, [onSuccess, setPending, userStore]);
    const validateSignUp = useCallback((values) => {
        const errors = {};
        if (values.gender === "select") {
            errors.gender = $t("Select a gender");
        }
        if (values.country === "select") {
            errors.country = $t("Select a country");
        }
        if (!nameValidation(values.firstName)) {
            errors.firstName = $t("Enter your first name here with first letter capital");
        }
        if (!nameValidation(values.lastName)) {
            errors.lastName = $t("Enter your last name here with first letter capital");
        }
        if (!postCodeValidation(values.postalCode, values.country)) {
            errors.postalCode = $t("Enter a correct zip code");
        }
        if (!onlyDigitsMaxLength(values.houseNumber)) {
            errors.houseNumber = $t("Enter your street number");
        }
        if (!checkLength(values.streetName)) {
            errors.streetName = $t("Enter your street name here");
        }
        if (!checkLength(values.city)) {
            errors.city = $t("Enter your location here");
        }
        if (!phoneValidation(values.telNum, false, [values.country])) {
            errors.telNum = $t("Enter valid telephone number");
        }
        if (!passwordValidation(values.password)) {
            errors.password = $t("Minimum 6 characters");
        }
        if (!passwordValidation(values.confirmPassword)) {
            errors.confirmPassword = $t("Minimum 6 characters");
        }
        else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = $t("Password does not match");
        }
        return errors;
    }, [$t]);
    const country = process.env.NEXT_PUBLIC_COUNTRY || "";
    const initialValues = useMemo(() => ({
        country: country === "US" ? "select" : country,
        gender: "select",
        firstName: "",
        middleName: "",
        lastName: "",
        postalCode: "",
        streetName: "",
        houseNumber: "",
        addition: "",
        city: "",
        telNum: "",
        email: userStore.unregisteredEmail,
        password: "",
        confirmPassword: "",
    }), [country, userStore.unregisteredEmail]);
    return {
        onSubmit,
        validateSignUp,
        initialValues,
    };
};
