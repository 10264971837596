import { useI18n } from "@jog/react-components";
import { changeBirthdayFormat } from "@jog/shared";
import { Section } from "components";
import { observer } from "mobx-react";
import { useStores } from "stores";
const AccountProfile = () => {
    const { userStore: { userData }, } = useStores();
    const { $t } = useI18n();
    const userBirthDate = (userData === null || userData === void 0 ? void 0 : userData.birthDate) ? changeBirthdayFormat(userData === null || userData === void 0 ? void 0 : userData.birthDate) : $t("Not available");
    const mappedUserData = [
        { fieldName: $t("First Name"), fieldValue: userData === null || userData === void 0 ? void 0 : userData.name },
        { fieldName: $t("insertion"), fieldValue: userData === null || userData === void 0 ? void 0 : userData.insertion },
        { fieldName: $t("Last Name"), fieldValue: userData === null || userData === void 0 ? void 0 : userData.familyName },
        { fieldName: $t("Gender"), fieldValue: $t(userData === null || userData === void 0 ? void 0 : userData.gender) },
        { fieldName: $t("Birthdate"), fieldValue: userBirthDate },
        {
            fieldName: $t("Telephone number"),
            fieldValue: userData === null || userData === void 0 ? void 0 : userData.phoneNumber,
        },
        // { fieldName: $t('E-mail address', true), fieldValue: userData.email }
    ];
    return (<Section id="profile-section" title={$t("Personal Data")} updatePageLink={"/my-account/update-profile"}>
            <ul>
                {mappedUserData.map((item) => (<li key={item.fieldName}>
                        <h3 className="text-sm font-medium leading-normal">{item.fieldName}</h3>
                        <p>{item.fieldValue}</p>
                    </li>))}
            </ul>
        </Section>);
};
export default observer(AccountProfile);
