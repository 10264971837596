import { AlertBox, FormikSelectField, LogoLoader, Modal } from "@/components";
import PrimaryButton from "@/components/PrimaryButton/PrimaryButton";
import { useMyAccountData } from "@/hooks/useMyAccountData";
import { getDutchAddress } from "@/lib/jog";
import { FormikInputField, InputWrapper, Link, useCheckDutchAddress, useI18n, } from "@jog/react-components";
import { checkLength, getListCountry, getUSListCountry, isObjectEmpty, nameValidation, onlyDigitsMaxLength, parsePhoneNumberByCountryCode, phoneValidation, postCodeValidation, scrollToWithOffset, } from "@jog/shared";
import { FormikFormWrapper, useTheme } from "@jog/theming";
import { Form, Formik } from "formik";
import { observer } from "mobx-react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { uid } from "react-uid";
const UpdateAddressForm = ({ address, isNewShippingAddress, isBillingAddress }) => {
    const { accountUpdateUserAddress, accountAddUserAddress, accountDeleteUserAddress } = useMyAccountData();
    const theme = useTheme();
    const { $t } = useI18n();
    const [isModalShown, setIsModalShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteErrored, setIsDeleteErrored] = useState(false);
    const [formSuccessMessage, setFormSuccessMessage] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("An error has occurred");
    const [isError, setIsError] = useState(false);
    const [isBtnSubmitting, setIsBtnSubmitting] = useState(false);
    const [isNewAddress, setIsNewAddress] = useState(false);
    const timer = useRef(null);
    const country = process.env.NEXT_PUBLIC_COUNTRY || "";
    const isCountryUS = country === "US";
    const countryOptions = getListCountry($t("CountryList"));
    const usCountryOptions = getUSListCountry(countryOptions);
    const { getAddress } = useCheckDutchAddress({ getDutchAddress });
    useEffect(() => {
        setFormSuccessMessage(false);
        return () => timer.current && clearTimeout(timer.current);
    }, [timer]);
    useEffect(() => {
        setIsNewAddress(isObjectEmpty(address));
    }, [address]);
    const toggleModal = () => setIsModalShown((prevState) => !prevState);
    const router = useRouter();
    const removeAddress = useCallback(async () => {
        try {
            setIsLoading(true);
            await accountDeleteUserAddress(address === null || address === void 0 ? void 0 : address.uid);
            await router.push(`/my-account/profile`);
        }
        catch (error) {
            console.log(error.message);
            setIsDeleteErrored(true);
        }
        finally {
            setIsLoading(false);
        }
    }, [accountDeleteUserAddress, address === null || address === void 0 ? void 0 : address.uid, router]);
    const initialValues = {
        country: isNewShippingAddress ? country : (address === null || address === void 0 ? void 0 : address.country) || "select",
        gender: (address === null || address === void 0 ? void 0 : address.gender) || "MALE",
        name: (address === null || address === void 0 ? void 0 : address.name) || "",
        insertion: (address === null || address === void 0 ? void 0 : address.insertion) || "",
        familyName: (address === null || address === void 0 ? void 0 : address.familyName) || "",
        streetName: (address === null || address === void 0 ? void 0 : address.streetName) || "",
        houseNumber: (address === null || address === void 0 ? void 0 : address.houseNumber) || "",
        addition: (address === null || address === void 0 ? void 0 : address.addition) || "",
        postalCode: (address === null || address === void 0 ? void 0 : address.postalCode) || "",
        city: (address === null || address === void 0 ? void 0 : address.city) || "",
        phoneNumber: (address === null || address === void 0 ? void 0 : address.phoneNumber) || "",
        defaultAddress: (address === null || address === void 0 ? void 0 : address.defaultAddress) || false,
    };
    const handleSubmit = useCallback(async (values) => {
        setIsError(false);
        setIsLoading(true);
        setIsBtnSubmitting(true);
        try {
            if (isNewAddress) {
                await accountAddUserAddress({
                    ...values,
                    phoneNumber: parsePhoneNumberByCountryCode(values.phoneNumber, values.country),
                });
            }
            else {
                await accountUpdateUserAddress({
                    ...values,
                    phoneNumber: parsePhoneNumberByCountryCode(values.phoneNumber, values.country),
                }, address === null || address === void 0 ? void 0 : address.uid);
            }
            setFormSuccessMessage(true);
            setIsBtnSubmitting(false);
            setIsLoading(false);
            window.scrollTo({ top: 0, behavior: "smooth" });
            timer.current = setTimeout(async () => {
                setFormSuccessMessage(false);
                await router.push(`/my-account/profile`);
            }, 3000);
        }
        catch (error) {
            setFormErrorMessage(error.message);
            setIsError(true);
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [accountAddUserAddress, accountUpdateUserAddress, address === null || address === void 0 ? void 0 : address.uid, isNewAddress, router]);
    const handlePreSubmitValidation = (errors) => {
        if (errors) {
            // Pass the error key name which is the same as field ID
            scrollToWithOffset(Object.keys(errors)[0]);
        }
    };
    return (<div className="text-sm leading-[1.5]">
            {isModalShown && (<Modal hideModal={toggleModal} headerTitle={$t("Delete Address")}>
                    <div className="modal-content">
                        {isDeleteErrored && (<AlertBox text={$t("We couldn't delete the address. Please try again later.")}/>)}
                        <p>{$t("Do you want to delete this address?")}</p>
                        <PrimaryButton onClickFunction={removeAddress}>{$t("Confirm")}</PrimaryButton>
                    </div>
                </Modal>)}
            {isLoading && <LogoLoader />}
            <Formik enableReinitialize initialValues={initialValues} validate={(values) => {
            const errors = {};
            if (values.country === "select") {
                errors.country = $t("Select a country");
            }
            if (!nameValidation(values.name)) {
                errors.name = $t("Enter your first name here with first letter capital");
            }
            if (!nameValidation(values.familyName)) {
                errors.familyName = $t("Enter your last name here with first letter capital");
            }
            if (!checkLength(values.streetName)) {
                errors.streetName = $t("Enter your street number");
            }
            if (!onlyDigitsMaxLength(values.houseNumber)) {
                errors.houseNumber = $t("Enter your street number");
            }
            if (!checkLength(values.city)) {
                errors.city = $t("Enter your location here");
            }
            if (!phoneValidation(values.phoneNumber, false, [values.country])) {
                errors.phoneNumber = $t("Enter valid telephone number");
            }
            if (!postCodeValidation(values.postalCode, values.country)) {
                errors.postalCode = $t("Enter a correct zip code");
            }
            return errors;
        }} onSubmit={handleSubmit}>
                {(values) => {
            var _a;
            return (<Form>
                        <FormikFormWrapper className="-mx-2 mt-7.5">
                            {formSuccessMessage && <AlertBox text={$t("Address updated")}/>}
                            {isError && <AlertBox isError={true} text={formErrorMessage}/>}
                            <div className="flex">
                                <div className="basis-full md:basis-8/12">
                                    <div className="mb-5">
                                        {isBillingAddress ? (
                // for billing address, all country can be selected
                <FormikSelectField id="country" label={`${$t("Country")} *`} name="country">
                                                <option disabled value="select">
                                                    {$t("Select")}
                                                </option>
                                                {(_a = Object.keys(countryOptions)) === null || _a === void 0 ? void 0 : _a.map((key) => (<option key={uid(key)} value={key}>
                                                        {countryOptions[key]}
                                                    </option>))}
                                            </FormikSelectField>) : (
                // for shipping address, only us locale can choose some country
                <FormikSelectField id="country" label={`${$t("Country")} *`} name="country" isDisabled={!isCountryUS}>
                                                <option disabled value="select">
                                                    {$t("Select")}
                                                </option>
                                                {!isCountryUS ? (<option value={country}>{countryOptions[country]}</option>) : (usCountryOptions === null || usCountryOptions === void 0 ? void 0 : usCountryOptions.map((country) => (<option value={country.code} key={uid(country.code)}>
                                                            {country.name}
                                                        </option>)))}
                                            </FormikSelectField>)}
                                    </div>
                                    <div className="mb-5">
                                        <FormikSelectField id="gender" name="gender" label={`${$t("Sex")} *`}>
                                            <option value="MALE">{$t("Male")}</option>
                                            <option value="FEMALE">{$t("Female")}</option>
                                        </FormikSelectField>
                                    </div>

                                    <InputWrapper>
                                        <FormikInputField id="name" fieldName="name" label={`${$t("First Name")} *`}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="insertion" fieldName="insertion" label={$t("insertion")}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="familyName" fieldName="familyName" label={`${$t("Last Name")} *`}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="postalCode" fieldName="postalCode" label={`${$t("Postcode")} *`} inputBlur={() => getAddress(values)}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="houseNumber" fieldName="houseNumber" inputMode="numeric" label={`${$t("Street number")} *`} inputBlur={() => getAddress(values)}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="addition" fieldName="addition" label={$t("Addition")}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="streetName" fieldName="streetName" label={`${$t("Street name")} *`}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="city" fieldName="city" label={`${$t("City")} *`}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="phoneNumber" fieldName="phoneNumber" type="tel" label={$t("Telephone number")}/>
                                    </InputWrapper>

                                    <div className="mb-5 flex flex-wrap gap-2 px-2">
                                        <Link href="/my-account/address">
                                            <PrimaryButton bgColor={theme.color.shuttleGray} buttonType="button">
                                                {$t("Cancel")}
                                            </PrimaryButton>
                                        </Link>
                                        {!isNewAddress && (<PrimaryButton onClickFunction={toggleModal} buttonType="button" bgColor={theme.color.monzaRed}>
                                                {$t("DELETE")}
                                            </PrimaryButton>)}
                                        <PrimaryButton onClickFunction={() => handlePreSubmitValidation(values.errors)} className="hover:bg-auto" bgColor={theme.color.black} color={theme.color.white} buttonType="submit" disabled={isBtnSubmitting}>
                                            {$t("SAVE")}
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </div>
                        </FormikFormWrapper>
                    </Form>);
        }}
            </Formik>
        </div>);
};
export default observer(UpdateAddressForm);
