import { getStoreURLById } from "@/util/helperFunctions";
import { useI18n } from "@jog/react-components";
import { isArrayEmpty, isObjectEmpty } from "@jog/shared";
import { useTheme } from "@jog/theming";
import { LogoLoader, OrderItem, Price, Section } from "components";
import { observer } from "mobx-react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useStores } from "stores";
import AccountPurchaseDetailsWrapper from "../AccountPurchaseDetails/AccountPurchaseDetailsWrapper";
const AccountPurchaseDetails = () => {
    var _a, _b;
    const { purchaseStore } = useStores();
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [orderVouchers, setOrderVouchers] = useState([]);
    const [purchaseDetails, setPurchaseDetails] = useState(null);
    const theme = useTheme();
    const { $t } = useI18n();
    // Get Purchase Data
    useEffect(() => {
        const fetchPurchaseData = async () => {
            setIsLoading(true);
            try {
                await purchaseStore.getPurchaseData();
            }
            catch (error) {
                console.log(error.message);
                setIsError(true);
                setErrorMessage(error.message);
            }
            finally {
                setIsLoading(false);
            }
        };
        if (isArrayEmpty(purchaseStore.purchaseData)) {
            fetchPurchaseData();
        }
    }, [purchaseStore]);
    useEffect(() => {
        const orderNumber = router.query.orderNumber;
        const updatedPurchaseDetails = purchaseStore.getOrder(orderNumber);
        if (updatedPurchaseDetails)
            setPurchaseDetails(updatedPurchaseDetails);
    }, [purchaseStore.purchaseData, purchaseStore, router.query.orderNumber]);
    // Filter Discounts by type of VOUCHER
    useEffect(() => {
        var _a;
        const filteredOrderVouchers = (_a = purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Discounts) === null || _a === void 0 ? void 0 : _a.filter((discount) => discount.discountType === "VOUCHER");
        if (!isArrayEmpty(filteredOrderVouchers))
            setOrderVouchers(filteredOrderVouchers);
    }, [purchaseStore.purchaseData, purchaseStore, purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Discounts]);
    // Get the store URL base on Store ID
    let storeDetails;
    if ((purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.storeType) === "PHYSICAL_STORE") {
        storeDetails = (<div>
                <p>
                    {purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Store.number}, {purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Store.address}
                </p>
                <p>
                    {purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Store.city} - {purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Store.postalCode}
                </p>
            </div>);
    }
    else {
        const storeDetailsData = getStoreURLById(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.storeId);
        storeDetails = (<p>
                <a href={storeDetailsData.url}>{storeDetailsData.name}</a>
            </p>);
        // storeDetails = <p>{purchaseDetails?.storeType}</p>;
    }
    return (<AccountPurchaseDetailsWrapper>
            {isLoading && <LogoLoader />}
            {isError && (<Section>
                    <p>{$t("We have encountered a problem retrieving your order")}:</p>
                    <p>{errorMessage}</p>
                </Section>)}
            {!isObjectEmpty(purchaseDetails) && (<Section>
                    {((purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.transactionType) === "RETURN" ||
                (purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.transactionType) === "PURCHASE-RETURN") && (<div>
                            <h2>{$t("Returned Order")}</h2>
                        </div>)}
                    <div>
                        <h3>{$t("Order date")}</h3>
                        <p>{purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.date}</p>
                    </div>
                    <div>
                        <h3>{$t("Order number")}</h3>
                        <p>{purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.orderNumber}</p>
                    </div>
                    <div className="shop-details">
                        <h3>{$t("Store")}</h3>
                        {storeDetails}
                    </div>
                    {(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.ShippingInfo) && (<div className="shipping-details">
                            <div>
                                <h3>{$t("Delivery address")}</h3>
                                <p>
                                    {purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.ShippingInfo.ShippingAddress.streetName}{" "}
                                    {purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.ShippingInfo.ShippingAddress.streetNumber}
                                </p>
                                <p>
                                    {purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.ShippingInfo.ShippingAddress.postalCode}{" "}
                                    {purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.ShippingInfo.ShippingAddress.city}
                                </p>
                                <p>{purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.ShippingInfo.ShippingAddress.country}</p>
                                <p>{purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.ShippingInfo.ShippingAddress.phone}</p>
                            </div>
                            <div>
                                <h3>{$t("Delivery method")}</h3>
                                <p>{purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.ShippingInfo.ShippingMethod.name}</p>
                            </div>
                        </div>)}
                    {(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Payments) && (<div className="payment-details">
                            <h3>{$t("Payment method")}</h3>
                            {purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Payments.map((payment, index) => (<p key={index}>{parsePaymentMethodCode(payment.paymentType)}</p>))}
                        </div>)}
                    <div className="vouchers-details">
                        {!isArrayEmpty(orderVouchers) && (<>
                                <h3>{$t("Vouchers")}</h3>
                                {orderVouchers.map((discount, index) => (<li key={index}>
                                        <p>{discount.description}</p>
                                        <Price price={discount.amount} isNegative={true} color={theme.color.mainRed} fontSize={theme.fontSize.priceElement}/>
                                    </li>))}
                            </>)}
                    </div>
                    <div className="order-details">
                        <h3>{$t("Order")}</h3>
                        {purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Items.map((item) => (<OrderItem key={item.EAN} itemDetails={item}/>))}
                    </div>
                    <div className="order-total">
                        <div>
                            <span>{$t("Subtotal")}:</span>
                            {/* <span className="bold">{Subtotal && Subtotal.regularPrice}</span> */}
                            {(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.transactionType) === "RETURN" ? (<Price price={(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Subtotal) && Math.abs(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Subtotal.paidPrice)} fontSize={theme.fontSize.priceElement}/>) : (<Price price={(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Subtotal) && (purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.Subtotal.paidPrice)} fontSize={theme.fontSize.priceElement}/>)}
                        </div>
                        {(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.TotalDiscount) !== 0 && (<div>
                                <span>{$t("Discount")}:</span>
                                {(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.transactionType) === "RETURN" ? (<Price price={Math.abs(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.TotalDiscount)} fontSize={theme.fontSize.priceElement} color={theme.color.mainRed} isNegative={true}/>) : (<Price price={purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.TotalDiscount} fontSize={theme.fontSize.priceElement} color={theme.color.mainRed} isNegative={true}/>)}
                            </div>)}
                        {(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.DeliveryCost) && (<div>
                                <span>{$t("Shipping costs")}: </span>
                                <Price price={(_a = purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.DeliveryCost) === null || _a === void 0 ? void 0 : _a.amount} fontSize={theme.fontSize.priceElement}/>
                            </div>)}
                        {(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.PaymentCost) && (<div>
                                <span>{$t("Payment costs")}:</span>
                                <Price price={(_b = purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.PaymentCost) === null || _b === void 0 ? void 0 : _b.amount} fontSize={theme.fontSize.priceElement}/>
                            </div>)}
                        <div>
                            <span>
                                <span className="bold">{$t("Total")} </span>
                                {$t("(inc. VAT)")}:
                            </span>
                            {(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.transactionType) === "RETURN" ? (<Price price={Math.abs(purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.amount)} fontSize={theme.fontSize.priceElement} color={theme.color.mainRed} isNegative={true}/>) : (<Price price={purchaseDetails === null || purchaseDetails === void 0 ? void 0 : purchaseDetails.amount} fontSize={theme.fontSize.priceElement}/>)}
                        </div>
                    </div>
                </Section>)}
        </AccountPurchaseDetailsWrapper>);
};
export default observer(AccountPurchaseDetails);
function parsePaymentMethodCode(paymentMethodCode) {
    switch (paymentMethodCode) {
        case "CASH":
            return "CASH";
        case "WEB_CREDIT_CARD":
        case "CREDIT_CARD":
            return "CREDITCARD";
        case "DEBIT_CARD":
            return "DEBITCARD";
        case "CHEQUE":
            return "CHEQUE";
        case "IRIS_CHEQUE":
            return "IRIS CHEQUE";
        case "BIRTHDAY_VOUCHER":
            return "BIRTHDAY VOUCHER";
        case "POSTPAY":
        case "WEB_POSTPAY":
            return "POSTPAY";
        case "WEB_PAYCONIQ":
        case "STORE_PAYCONIQ":
            return "STORE PAYCONIQ";
        case "WEB_PAYPAL":
            return "Paypal";
        case "WEB_IDEAL":
            return "IDEAL";
        case "WEB_GIFTCARD":
        case "GIFT_CARD":
            return "GIFTCARD";
        default:
            return paymentMethodCode;
    }
}
