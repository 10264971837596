import PrimaryButton from "@/components/PrimaryButton/PrimaryButton";
import { AddressListBase, I18n } from "@jog/react-components";
import { Link } from "@jog/react-components";
import { useTheme } from "@jog/theming";
import { AddressItem } from "components";
export function AddressList({ userOtherAddresses }) {
    const theme = useTheme();
    return (<AddressListBase Link={Link} userOtherAddresses={userOtherAddresses} AddressItem={AddressItem}>
            <PrimaryButton bgColor={theme.color.black} color={theme.color.white} fontSize={"18px"} margin={"10px 0 0"}>
                <I18n t="Add new address"/>
            </PrimaryButton>
        </AddressListBase>);
}
