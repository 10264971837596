import PrimaryButton from "@/components/PrimaryButton/PrimaryButton";
import { useI18n } from "@jog/react-components";
import { getCountryNameByCode, isObjectEmpty } from "@jog/shared";
import { useTheme } from "@jog/theming";
import { observer } from "mobx-react";
import { useRef, useState } from "react";
import { useStores } from "stores";
import DisableLocationModal from "./DisableLocationModal";
// Refresh page/store after succsesful location retrival
const AccountLocationSharing = () => {
    const { userStore } = useStores();
    const [showRevokeModal, setShowrevokeModal] = useState(false);
    const [isLocationUpdated, setIsLocationUpdated] = useState(false);
    const isInitialMount = useRef(true);
    const theme = useTheme();
    // TODO: Try to understand what this is doing, why it's navigating away.
    // useEffect(() => {
    //   if (isInitialMount.current) {
    //     isInitialMount.current = false;
    //   } else {
    //     console.log('location updated ' + isLocationUpdated)
    //     navigate(`/my-account`);
    //   }
    // }, [isLocationUpdated]);
    const handleGetLocation = async () => {
        try {
            const userLocation = await userStore.getLocation();
            console.log(userLocation);
            setIsLocationUpdated(true);
        }
        catch (error) {
            console.log(error.message);
        }
    };
    const handleRevokeLocationPermissions = async () => {
        await userStore.revokeLocationPermissions();
        setShowrevokeModal(true);
    };
    const toggleRevokeModal = () => setShowrevokeModal((prev) => !prev);
    const { $t } = useI18n();
    return (<div>
            {showRevokeModal && <DisableLocationModal hideModal={toggleRevokeModal}/>}
            <h3>{$t("Location sharing")}</h3>
            {!isObjectEmpty(userStore.userLocation) && (<p>
                    {$t("Current location")}:{" "}
                    {$t(getCountryNameByCode(userStore.userLocation.countryCode, userStore.userLocation.countryName))}
                </p>)}
            {userStore.isLocationEnabled ? (<PrimaryButton color="white" bgColor={theme.color.monzaRed} className="hover:bg-auto" onClickFunction={handleRevokeLocationPermissions}>
                    {$t("Stop location sharing")}
                </PrimaryButton>) : (<PrimaryButton color="white" bgColor={theme.color.taraBlue} className="hover:bg-auto" onClickFunction={handleGetLocation}>
                    {$t("Enable location sharing")}
                </PrimaryButton>)}
        </div>);
};
export default observer(AccountLocationSharing);
