import { styled } from "@jog/theming";
const AccountVoucherDetailsWrapper = styled.div `
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    text-align: center;
    picture {
        img {
            margin-bottom: ${(props) => props.theme.padding.large};
            width: 100%;

            height: auto;
        }
    }
    .expiration-date {
        font-size: 14px;
        text-transform: uppercase;
        color: ${(props) => props.theme.color.black};
    }
    .voucher-section {
        margin-bottom: 20px;
    }
    hr {
        width: 100%;
        border-top: 1px solid ${(props) => props.theme.color.black};
        margin: 4px 0 8px;
    }
    .legal-notice p {
        font-size: 14px;
        line-height: 20px;
        color: ${(props) => props.theme.color.black};
        a {
            color: ${(props) => props.theme.color.black};
        }
    }
    .redeemed {
        cursor: pointer;
        background: white;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        h2 {
            text-decoration: line-through;
        }
    }
`;
export default AccountVoucherDetailsWrapper;
