import { NewsForm } from "@/containers/SocialSection/NewsForm";
import { useSocial } from "@/hooks/useSocial";
import { Link, NextImage, useI18n } from "@jog/react-components";
import { Container, HR } from "components";
export const SocialSection = () => {
    const socials = useSocial();
    const { $t } = useI18n();
    return (<div className="mt-3 w-full bg-white  px-4 pt-10 pb-3 lg:px-0">
            <Container>
                <HR className="mb-16 mt-10"/>
                <div className="flex flex-wrap items-center justify-between lg:flex-nowrap">
                    <div className="mb-6 flex items-center lg:mb-0">
                        {socials.map((item, index) => (<Link href={item.href} title="" target="_blank" rel="noopener" key={index} css={{
                textDecoration: "none !important",
            }}>
                                <div className="mr-5 flex md:mr-6 xl:mr-8">
                                    <NextImage className="h-5 w-5" blur={false} src={item.image} alt="Social network icon" width={20} height={20}/>
                                </div>
                            </Link>))}
                    </div>
                    <div className="flex flex-wrap items-center lg:flex-nowrap">
                        <div className="mr-8 font-primary text-13 font-medium uppercase text-black xl:mr-8">
                            {$t("Subscribe newsletter")}
                        </div>
                        <NewsForm source="footer"/>
                    </div>
                </div>
                <HR className="mt-16"/>
            </Container>
        </div>);
};
