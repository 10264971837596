import { Section } from "@/components/Section/Section";
import AccountLocationSharing from "@/containers/MyAccountContent/AccountLocationSharingSection";
import { useI18n } from "@jog/react-components";
import React from "react";
const AccountPreferences = () => {
    const { $t } = useI18n();
    return (<Section id="preferences-section" title={$t("Preferences")}>
            <AccountLocationSharing />
        </Section>);
};
export default React.memo(AccountPreferences);
