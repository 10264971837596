import { HR } from "@/components/HR/HR";
import { Price } from "@/components/Price/Price";
import { useI18n } from "@jog/react-components";
import { useTheme } from "@jog/theming";
const TotalPriceWrapper = ({ children }) => {
    return <div className="mt-6.25 mb-7.5 flex justify-between font-primary text-lg font-medium">{children}</div>;
};
const PriceWrapper = ({ children }) => {
    return <div className="mb-5 flex justify-between text-sm">{children}</div>;
};
const PaymentFooter = ({ order }) => {
    const theme = useTheme();
    const { $t } = useI18n();
    return (<div className="mt-7.5 grid grid-cols-12">
            <div className="col-span-12 md:col-span-5 md:col-start-8 lg:col-span-4 lg:col-start-9">
                <PriceWrapper>
                    <div>{$t("Subtotal")}:</div>
                    <Price fontSize="18px" price={order.subtotal.centAmount}/>
                </PriceWrapper>
                {order.totalDiscount.centAmount > 0 && (<PriceWrapper>
                        <div>{$t("Discount")}:</div>
                        <Price fontSize="18px" color={theme.color.mainRed} price={order.totalDiscount.centAmount}/>
                    </PriceWrapper>)}
                {order.shippingCosts && order.shippingCosts.costs.centAmount > 0 && (<PriceWrapper>
                        <div>{$t("Shipping costs")}:</div>
                        <Price fontSize="16px" price={order.shippingCosts.costs.centAmount}/>
                    </PriceWrapper>)}
                {order.paymentCosts && order.paymentCosts.centAmount > 0 && (<PriceWrapper>
                        <div>{$t("Payment shipping cost")}:</div>
                        <Price fontSize="16px" price={order.paymentCosts.centAmount}/>
                    </PriceWrapper>)}
                <HR css={(theme) => ({
            borderTop: `1px solid ${theme.color.lightSandGrey}`,
        })}/>
                <TotalPriceWrapper>
                    {$t("Total inc. VAT")}
                    <Price fontSize="18px" price={order.total.centAmount}/>
                </TotalPriceWrapper>
            </div>
        </div>);
};
export default PaymentFooter;
