import { styled } from "@jog/theming";
const SimpleList = styled.ul `
    margin: 0;
    padding: 0;
    li {
        margin-bottom: ${(props) => props.theme.padding.standard};
    }
`;
export const AddressListBase = ({ userOtherAddresses, AddressItem, linkHref, children, Link, }) => {
    const defaultLink = "/my-account/edit-address/?addressid=add-address";
    // const history = useHistory();
    // const [selectedAddress, setSelectedAddress] = useState(userAddress);
    // const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    // const [isLoading, setIsLoading] = useState(false);
    // const [isError, setIsError] = useState(false);
    // const [errorMessage, setErrorMessage] = useState('');
    // const handleChange = event => {
    //   const newSelectedAddress = JSON.parse(event.target.value);
    //   setSelectedAddress(newSelectedAddress);
    //   setIsBtnDisabled(false);
    // };
    // const handleSubmit = async () => {
    //   try {
    //     // Make API call to update in backend
    //     const newDefaultAddress = { ...selectedAddress, defaultAddress: true };
    //     // console.log(`This address will be set as default: ${JSON.stringify(newDefaultAddress)}`);
    //     setIsLoading(true);
    //     setIsBtnDisabled(true);
    //     await accountUpdateUserAddress(newDefaultAddress, newDefaultAddress.uid);
    //     // Update local userStore with new UserAddressses
    //     await getUserAddresses();
    //     setIsLoading(false);
    //   } catch (error) {
    //     setIsError(true);
    //     console.log(error.message);
    //     setErrorMessage(I18n("We couldn't update the address. Please try again later."));
    //   } finally {
    //     setIsLoading(false);
    //   }
    // };
    return (<form>
            <SimpleList>
                {userOtherAddresses.map((address) => (<AddressItem key={address.uid} title={`${address.name} ${(address === null || address === void 0 ? void 0 : address.insertion) || ""} ${address.familyName}`} address={address}/>))}
            </SimpleList>
            <div className="flex flex-row flex-nowrap items-baseline justify-between">
                {/* <Button onClick={handleSubmit} className="update-default mb-2.5 w-auto block" disabled={isBtnDisabled}>
            > {I18n('SAVE')}
            </Button> */}
                <Link href={linkHref ? linkHref : defaultLink}>{children}</Link>
                {/* {isError && <AlertBox isError={true} text={errorMessage} />} */}
            </div>
        </form>);
};
