import { AlertBox } from "@/components/AlertBox/AlertBox";
import { LogoLoader } from "@/components/LogoLoader/LogoLoader";
import PrimaryButton from "@/components/PrimaryButton/PrimaryButton";
import { Section } from "@/components/Section/Section";
import { useMyAccountData } from "@/hooks/useMyAccountData";
import { FormikInputField, InputWrapper, Link, useI18n, useValidateEmail } from "@jog/react-components";
import { passwordValidation } from "@jog/shared";
import { FormikFormWrapper } from "@jog/theming";
import { Form, Formik } from "formik";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { useStores } from "stores";
const UpdateEmail = () => {
    const { userStore } = useStores();
    const { changeEmailAddress } = useMyAccountData();
    const [isBtnSubmitting, setIsBtnSubmitting] = useState(false);
    const { validateEmail } = useValidateEmail();
    const handleSubmit = useCallback(async (values) => {
        userStore.removeMyAccountAlerts();
        setIsBtnSubmitting(true);
        await changeEmailAddress(values.newEmail, values.password);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setIsBtnSubmitting(false);
    }, [changeEmailAddress, userStore]);
    const { $t } = useI18n();
    return (<Section title={$t("E-mail address")}>
            {userStore.isChangeEmailSuccess && <AlertBox text={$t("Email changed")}/>}
            {userStore.isChangeEmailError && (<AlertBox isError={true} text={$t("Something went wrong please try again")}/>)}
            {isBtnSubmitting && <LogoLoader />}
            {userStore.userData ? (<Formik initialValues={{
                newEmail: userStore.userData.email,
                confirmNewEmail: "",
                password: "",
            }} validate={(values) => {
                var _a;
                const errors = {};
                if (((_a = userStore.userData) === null || _a === void 0 ? void 0 : _a.email) === values.newEmail) {
                    errors.newEmail = $t("Enter new email");
                }
                else if (!validateEmail(values.newEmail)) {
                    errors.newEmail = $t("Invalid email address");
                }
                else if (values.newEmail !== values.confirmNewEmail) {
                    errors.confirmNewEmail = $t("Emails dont match");
                }
                if (!validateEmail(values.confirmNewEmail)) {
                    errors.confirmNewEmail = $t("Invalid email address");
                }
                if (!passwordValidation(values.password)) {
                    errors.password = $t("Minimum 6 characters");
                }
                return errors;
            }} onSubmit={handleSubmit}>
                    {() => (<Form>
                            <FormikFormWrapper className="-mx-2 mt-7.5">
                                <div className="flex flex-wrap">
                                    <div className="basis-full md:basis-8/12">
                                        <InputWrapper>
                                            <FormikInputField id="newEmail" fieldName="newEmail" label={$t("New email address *")}/>
                                        </InputWrapper>

                                        <InputWrapper>
                                            <FormikInputField id="confirmNewEmail" fieldName="confirmNewEmail" label={$t("Confirm new email address *")}/>
                                        </InputWrapper>

                                        <InputWrapper>
                                            <FormikInputField id="password" fieldName="password" type="password" label={`${$t("Password")} *`}/>
                                        </InputWrapper>

                                        <div className="mt-5 flex flex-wrap gap-2 px-2 lg:gap-0">
                                            <Link href="/my-account/profile">
                                                <PrimaryButton className="!bg-gray-shuttle !text-white hover:bg-auto" onClickFunction={() => userStore.removeMyAccountAlerts()}>
                                                    {$t("Cancel")}
                                                </PrimaryButton>
                                            </Link>
                                            <PrimaryButton className="!bg-black !text-white hover:bg-auto" buttonType="submit" disabled={isBtnSubmitting}>
                                                {$t("Update")}
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                </div>
                            </FormikFormWrapper>
                        </Form>)}
                </Formik>) : null}
        </Section>);
};
export default observer(UpdateEmail);
