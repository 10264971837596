import { Container, WhiteBox } from "components";
import { observer } from "mobx-react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useStores } from "stores";
import SignUpForm from "./SignUpForm";
export const SignUpContent = observer(() => {
    const { userStore } = useStores();
    useEffect(() => {
        userStore.clearRegistrationError();
    }, [userStore]);
    const router = useRouter();
    useEffect(() => {
        if (!userStore.unregisteredEmail && router.isReady) {
            router.push(`/login`);
        }
    }, [router, userStore.unregisteredEmail]);
    return (<Container>
            <WhiteBox>
                <Row>
                    <Col sm="10">
                        <SignUpForm />
                    </Col>
                </Row>
            </WhiteBox>
        </Container>);
});
