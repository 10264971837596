import { useGate } from "@jog/react-components";
import { useRouter } from "next/router";
import MenuIcon from "../../../../public/images/icon-dots.svg";
import HomeIconActive from "../../../../public/images/icon-home-active.svg";
import HomeIcon from "../../../../public/images/icon-home.svg";
import PurchasesIconActive from "../../../../public/images/icon-purchases-active.svg";
import PurchasesIcon from "../../../../public/images/icon-purchases.svg";
import UserIconActive from "../../../../public/images/icon-user-active.svg";
import UserIcon from "../../../../public/images/icon-user.svg";
import FixedNavigationItem from "./FixedNavigationItem";
function MyAccountFixedNavigation() {
    const router = useRouter();
    const { value } = useGate("orders-return-2");
    return (<div className="fixed bottom-0 z-[2] flex w-full justify-between border border-black bg-white py-1.5 px-3.75 text-center text-8 sm:text-10 lg:hidden">
            <FixedNavigationItem link="/my-account" NavIcon={router.pathname === "/my-account" ? HomeIconActive : HomeIcon} label="HOME" iconWidth="24px"/>
            <FixedNavigationItem link="/my-account/profile" NavIcon={router.pathname === "/my-account/profile" ? UserIconActive : UserIcon} label="My Preferences" iconWidth="21px"/>
            {!value ? (<FixedNavigationItem link="/my-account/purchases" NavIcon={router.pathname === "/my-account/purchases" ? PurchasesIconActive : PurchasesIcon} label="My Purchases" iconWidth="28px"/>) : (<FixedNavigationItem link="/my-account/order-list" NavIcon={router.pathname === "/my-account/order-list" ? PurchasesIconActive : PurchasesIcon} label="My Order List" iconWidth="28px"/>)}

            {/* TODO: see https://fyayc-am.atlassian.net/browse/JOGG-2030?focusedCommentId=16592 for commenting reasons */}
            {/* <FixedNavigationItem
      link="/my-account/vouchers"
      icon={activeIconPath === '/my-account/vouchers' ? VouchersIconActive : VouchersIcon}
      isActive={activeIconPath === '/my-account/vouchers'}
      label="VOUCHERS"
      iconWidth="28px"
    /> */}
            <FixedNavigationItem link="/service" NavIcon={MenuIcon} label="SERVICE" iconWidth="28px"/>
        </div>);
}
export default MyAccountFixedNavigation;
