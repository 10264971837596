import { useI18n } from "@jog/react-components";
import { Section } from "components";
import { observer } from "mobx-react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useStores } from "stores";
import UpdateAddressForm from "./UpdateAddressForm";
const AccountChangeAddress = () => {
    const { userStore: { userAddresses }, } = useStores();
    const [isNewShippingAddress, setIsNewShippingAddress] = useState(false);
    const [isBillingAddress, setIsBillingAddress] = useState(false);
    const [address, setAddress] = useState({});
    const { query } = useRouter();
    const { $t } = useI18n();
    useEffect(() => {
        const fetchUserAddressList = async () => {
            if (query.addressid === "add-address") {
                setAddress({});
                setIsNewShippingAddress(true);
            }
            else {
                const addressFromId = userAddresses.find((address) => address.uid === query.addressid);
                setAddress(addressFromId);
                setIsNewShippingAddress(false);
            }
            setIsBillingAddress(query.type === "billingAddress");
        };
        fetchUserAddressList();
    }, [query.addressid, userAddresses, query.type]);
    return (<Section title={$t("Update ADDRESS")}>
            <UpdateAddressForm address={address} isNewShippingAddress={isNewShippingAddress} isBillingAddress={isBillingAddress}/>
        </Section>);
};
export default observer(AccountChangeAddress);
