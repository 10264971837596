import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { ContentLoader, Link, NextImage } from "@jog/react-components";
import { Container, HR } from "components";
import CountrySwitcher from "./CountrySwitcher";
export const Footer = () => {
    var _a;
    const layout = useLayout();
    const footer = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.footer;
    if (!footer)
        return (<div className="w-full bg-white py-10 px-4 lg:px-0">
                <Container>
                    <ContentLoader width="100%" height="100%"/>
                </Container>
            </div>);
    return (<div className="w-full bg-white py-10 px-4 lg:px-0">
            <Container>
                <div className="flex flex-wrap gap-x-4 lg:flex-nowrap">
                    {footer.columns.map((col, colIndex) => (<div className="mb-2 basis-full lg:mb-0 lg:basis-3/12" key={colIndex}>
                            <h4 className="mb-0 mt-2 font-primary text-sm font-medium uppercase lg:mt-0 lg:mb-3.75">
                                <Link href={col.url}>{col.title}</Link>
                            </h4>
                            <ul className="list-none p-0">
                                {col.title === "Land" && (<li className="py-2 px-0 text-sm">
                                        <CountrySwitcher items={col.items}/>
                                    </li>)}

                                {col.title !== "Land" &&
                col.items.map((item, index) => (<li className="py-2 px-0 text-sm" key={index}>
                                            <Link className="hover:underline" href={item.url}>
                                                {item.text}
                                            </Link>
                                        </li>))}
                            </ul>
                        </div>))}
                </div>
                <HR className="mx-0 mt-5 mb-7.5 border-t border-solid border-gray-darker md:mt-[110px] md:mb-[110px] md:!border-t-0"/>

                <div className="flex flex-wrap lg:flex-nowrap">
                    {footer.logos.map((col, colIndex) => (<div className="basis-full xl:basis-6/12" key={`logos_${colIndex}`}>
                            {col.map((logo, index) => (<span className="mr-6.25 mb-7.5 inline-block lg:mb-0" key={index}>
                                    <NextImage blur={false} src={logo.src} alt="Footer logo" className="w-auto" width={(logo.width / logo.height) * 15} height={15}/>
                                </span>))}
                        </div>))}
                    {footer.legal.map((col, colIndex) => (<div className="basis-full xl:basis-6/12" key={`legal_${colIndex}`}>
                            <div className="text-left sm:text-right">
                                {col.map((legal, index) => (<Link className="block py-2.5 px-0 !text-13 sm:inline-block sm:whitespace-nowrap sm:py-0 sm:px-3.75" href={legal.url} key={index}>
                                        {legal.label}
                                    </Link>))}
                            </div>
                        </div>))}
                </div>
                <HR className="my-5 !border-t-0 border-gray-darker md:!border-t"/>
            </Container>
        </div>);
};
