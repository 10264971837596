import { Button } from "@/components/Button/Button";
import { HintText } from "@/components/HintText/HintText";
import { wrongCountryErrorMessage } from "@/util/helperFunctions";
import { useI18n, useValidateEmail } from "@jog/react-components";
import { dispatchInputChange } from "@jog/shared";
import { styled, useTheme } from "@jog/theming";
import classNames from "classnames";
import { AlertBox, Container, GRichText, Input, LogoLoader, Modal, ReCaptchaDisclaimer, WhiteBox } from "components";
import { observer } from "mobx-react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useStores } from "stores";
import LoginForm from "./LoginForm";
import LoginResetPassword from "./LoginResetPassword";
const LoginContentComponent = ({ wrapCls, inputType = "main", otherOptionsShow = true, outerText, hideModal, }) => {
    var _a;
    const { userStore } = useStores();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isUserEmailNotRegistered, setIsUserEmailNotRegistered] = useState(false);
    const [email, setEmail] = useState("");
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [availableError, setAvailableError] = useState(null);
    const [isResetPasswordEmailNotRegistered, setIsResetPasswordEmailNotRegistered] = useState(false);
    const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useState(null);
    const [isResetPasswordEmailSent, setIsResetPasswordEmailSent] = useState(false);
    const { $t } = useI18n();
    const theme = useTheme();
    const router = useRouter();
    const getReCaptchaToken = useCallback(async (action) => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }
        return await executeRecaptcha(action);
    }, [executeRecaptcha]);
    const goToStepOne = async () => {
        setShowLogin(false);
        setEmail("");
        // Trigger check if Enter key is pressed
        setTimeout(() => {
            var _a;
            (_a = emailElement.current) === null || _a === void 0 ? void 0 : _a.focus();
        }, 100);
    };
    const checkIfUserEmailIsRegistered = useCallback(async () => {
        var _a, _b;
        const token = await getReCaptchaToken("validateEmail");
        try {
            const { available: isNotRegistered, message } = await userStore.checkIfUserExists(email, token);
            if (message) {
                setAvailableError({ message });
                return;
            }
            setAvailableError(null);
            setIsUserEmailNotRegistered(isNotRegistered);
            const lowerCaseEmail = String(email).toLowerCase();
            if (isNotRegistered) {
                userStore.unregisteredEmail = lowerCaseEmail;
                router.push(`/signup`);
            }
            else {
                setShowLogin(true);
                setTimeout(() => {
                    dispatchInputChange('input[name="username"]', lowerCaseEmail);
                }, 0);
                // Focus on Password field
                setTimeout(() => {
                    const passwordInput = document.getElementsByName("password");
                    if (passwordInput[0])
                        passwordInput[0].focus();
                }, 100);
            }
        }
        catch (e) {
            setAvailableError({
                error: (_a = e.body) === null || _a === void 0 ? void 0 : _a.error,
                userCountryCode: (_b = e.body) === null || _b === void 0 ? void 0 : _b.country,
            });
        }
    }, [email, getReCaptchaToken, router, userStore]);
    const [captchaFail, setCaptchaFail] = useState("");
    const checkEmailForPasswordReset = useCallback(async (email) => {
        const token = await getReCaptchaToken("validateEmail");
        setCaptchaFail("");
        const { available: isNotRegistered, message } = await userStore.checkIfUserExists(email, token);
        if (message) {
            setCaptchaFail(message);
            return;
        }
        if (isNotRegistered) {
            setIsResetPasswordEmailNotRegistered(true);
        }
        else {
            const response = await userStore.sendEmailForForgotPassword(email);
            if (response) {
                setIsResetPasswordEmailSent(true);
            }
        }
    }, [getReCaptchaToken, userStore]);
    const listenToEnter = useCallback((event) => {
        if (event.key === "Enter" && !isEmailInvalid) {
            checkIfUserEmailIsRegistered();
        }
    }, [checkIfUserEmailIsRegistered, isEmailInvalid]);
    useEffect(() => {
        var _a;
        const url = new URL(window.location.href);
        const paramResetPasswordSuccess = url.searchParams.get("resetPasswordSuccess");
        (_a = emailElement.current) === null || _a === void 0 ? void 0 : _a.focus();
        if (paramResetPasswordSuccess !== null) {
            setIsResetPasswordSuccess(paramResetPasswordSuccess);
        }
        // Collect param values if coming from the special password reset email link (example.com/login?email=abc@gmail.com&rp=true)
        const paramEmail = url.searchParams.get("email");
        const paramResetPassword = url.searchParams.get("rp");
        if (paramEmail && paramResetPassword) {
            setEmail(paramEmail);
            setShowModal(true);
        }
    }, [listenToEnter]);
    const { validateEmail } = useValidateEmail();
    const getEmailValue = useCallback((e) => {
        setEmail(e.target.value);
        setIsEmailInvalid(!validateEmail(e.target.value));
        setIsBtnDisabled(!validateEmail(e.target.value));
    }, [validateEmail]);
    const toggleModal = (show) => {
        setShowModal(!showModal);
        if (!show) {
            setIsResetPasswordEmailNotRegistered(false);
            setIsResetPasswordEmailSent(false);
        }
    };
    const withSiteLink = (textMessage) => {
        return <span dangerouslySetInnerHTML={{ __html: textMessage }}></span>;
    };
    const emailElement = React.useRef(null);
    const session = useSession();
    useEffect(() => {
        if (session.status === "authenticated") {
            router.push(`/my-account`);
        }
    }, [router, session.data, session.status]);
    if (session.status === "authenticated") {
        return (<div className="text-sm">
                <Container className="mb-14">
                    <LogoLoader />
                </Container>
            </div>);
    }
    return (<div className={classNames("text-sm", wrapCls)}>
            <Container className={classNames(inputType === "main" ? "mb-14" : "mb-5")}>
                {userStore.isLoading && <LogoLoader />}
                {isResetPasswordSuccess === "false" ? (<AlertBox isError={true} text={$t("The link you have used is not (more) valid")}/>) : ("")}
                {isResetPasswordSuccess === "true" ? (<AlertBox text={$t("Lucky you can now log in with your new password")}/>) : ("")}
                <WhiteBox>
                    {outerText && (<div className="mb-12">
                            <GRichText h1Class="text-2xl font-normal" h3Class="text-sm lg:text-base font-normal" paragraphClass="text-11 lg:text-sm" field={outerText}/>
                        </div>)}
                    {!isUserEmailNotRegistered && !showLogin && (<>
                            {!outerText && (<>
                                    <h1 className="my-5 text-lg">{$t("Log in / Register")}</h1>
                                    <p className="mb-5.5">
                                        {$t(`Enter your e-mail address. We will then check whether you already have an account.`)}
                                    </p>
                                </>)}
                            <div className="mt-5.5 flex flex-wrap items-center">
                                <div className={classNames("w-full basis-full lg:basis-3/12", inputType === "secondary" ? "hidden lg:block" : "")}>
                                    <label htmlFor="emailOne" className="mt-2.5">
                                        {$t("E-mail address")} *
                                    </label>
                                </div>

                                <div className={classNames("w-full basis-full", {
                "lg:basis-7/12": inputType === "main",
                "w-full basis-full lg:basis-9/12": inputType === "secondary",
            })}>
                                    {availableError && (<HintTextEx>
                                            {(_a = withSiteLink(availableError.message ||
                    wrongCountryErrorMessage(availableError.userCountryCode))) !== null && _a !== void 0 ? _a : availableError.error}
                                        </HintTextEx>)}
                                    <div className={classNames("flex items-center", {
                "border pl-3 lg:pl-0": inputType === "secondary",
            })}>
                                        {inputType === "secondary" && (<div className="block shrink-0 lg:hidden">
                                                <label htmlFor="emailOne" className="mt-2.5">
                                                    {$t("E-mail address")} *
                                                </label>
                                            </div>)}
                                        <Input className={classNames("!h-11.5 w-full py-2.75 px-3 !text-left", inputType === "secondary" ? "!border-none" : "border border-gray-medium")} ref={emailElement} id="emailOne" onChange={getEmailValue} onKeyUp={listenToEnter} type="email"/>
                                    </div>

                                    {isEmailInvalid && (<div className="mt-1.25 text-right text-13">{$t("Invalid email address")}</div>)}
                                </div>
                                <div className={classNames("flex basis-full justify-end", inputType === "secondary" ? "mt-3.75 lg:basis-full" : "mt-6 lg:basis-10/12")}>
                                    <Button className="hover:bg-auto" bgColor={theme.color.black} color={theme.color.white} fontWeight="600" onClick={checkIfUserEmailIsRegistered} disabled={isBtnDisabled}>
                                        {$t("Log in / Register")}
                                    </Button>
                                </div>
                            </div>
                        </>)}

                    {!isUserEmailNotRegistered && showLogin && (<LoginForm emailForCheck={email} isCheckout={false} toggleModal={() => toggleModal(true)} goToStepOne={goToStepOne} inputType={inputType} otherOptionsShow={otherOptionsShow} hideModal={hideModal}/>)}
                    <ReCaptchaDisclaimer />
                </WhiteBox>
            </Container>

            {showModal && (<Modal headerTitle={$t("Reset password")} hideModal={() => toggleModal(false)} width="50%">
                    {isResetPasswordEmailNotRegistered && (<AlertBox isWarning={true} text={$t("WE DO NOT HAVE AN ACCOUNT FOR THIS EMAIL ADDRESS, THEREFOR CREATE A NEW ACCOUNT")}/>)}
                    {captchaFail && <AlertBox isWarning text={$t(captchaFail)}/>}
                    {isResetPasswordEmailSent && (<AlertBox text={$t("WE HAVE SENT YOU AND E-MAIL STATING HOW YOU CAN RESET YOUR PASSWORD")}/>)}
                    {!isResetPasswordEmailNotRegistered && !isResetPasswordEmailSent && (<LoginResetPassword checkEmailForPasswordReset={checkEmailForPasswordReset} email={email}/>)}
                </Modal>)}
        </div>);
};
const HintTextEx = styled(HintText) `
    a {
        color: #ff0000;
        text-decoration: none;
        font-weight: bold;
        &:hover {
            text-decoration: underline;
        }
    }
`;
export const LoginContent = observer(LoginContentComponent);
