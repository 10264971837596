import PrimaryButton from "@/components/PrimaryButton/PrimaryButton";
import { useMyAccountData } from "@/hooks/useMyAccountData";
import { FormikInputField, InputWrapper, Link, useI18n } from "@jog/react-components";
import { localStorage, nameValidation, validateBirthDate } from "@jog/shared";
import { FormikFormWrapper } from "@jog/theming";
import { AlertBox, FormikSelectField, LogoLoader, Section } from "components";
import { Form, Formik } from "formik";
import { observer } from "mobx-react";
import { useState } from "react";
import { useStores } from "stores";
const UpdateProfile = () => {
    var _a, _b, _c, _d, _e, _f;
    const { $t } = useI18n();
    const { userStore } = useStores();
    const { updateUser } = useMyAccountData();
    const [isBtnSubmitting, setIsBtnSubmitting] = useState(false);
    const initialValues = {
        gender: (_a = userStore.userData) === null || _a === void 0 ? void 0 : _a.gender,
        firstName: (_b = userStore.userData) === null || _b === void 0 ? void 0 : _b.name,
        middleName: (_c = userStore.userData) === null || _c === void 0 ? void 0 : _c.insertion,
        lastName: (_d = userStore.userData) === null || _d === void 0 ? void 0 : _d.familyName,
        birthDate: (_e = userStore.userData) === null || _e === void 0 ? void 0 : _e.birthDate,
        phoneNumber: (_f = userStore.userData) === null || _f === void 0 ? void 0 : _f.phoneNumber,
    };
    const handleSubmit = async (values) => {
        userStore.removeMyAccountAlerts();
        setIsBtnSubmitting(true);
        await updateUser(values.gender, values.firstName, values.middleName, values.lastName, values.birthDate, values.phoneNumber);
        localStorage.setItem("name", values.firstName);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setIsBtnSubmitting(false);
    };
    const handleCancel = () => {
        userStore.removeMyAccountAlerts();
    };
    return (<Section title={$t("Personal Data")}>
            {isBtnSubmitting && <LogoLoader />}
            <Formik initialValues={initialValues} validate={(values) => {
            const errors = {};
            if (!nameValidation(values.firstName)) {
                errors.firstName = $t("Enter your first name here with first letter capital");
            }
            if (!nameValidation(values.lastName)) {
                errors.lastName = $t("Enter your last name here with first letter capital");
            }
            if (!validateBirthDate(values.birthDate)) {
                errors.birthDate = $t("Enter your birth date in a correct format dd-mm-yyyy");
            }
            return errors;
        }} onSubmit={handleSubmit}>
                {() => (<Form>
                        <FormikFormWrapper className="-mx-2 mt-7.5">
                            {userStore.isUpdateProfileSuccess && <AlertBox text={$t("Profile updated")}/>}
                            {userStore.isUpdateProfileError && (<AlertBox isError={true} text={$t("Something went wrong please try again")}/>)}
                            <div className="flex flex-wrap">
                                <div className="basis-full md:basis-8/12">
                                    <div className="mb-5">
                                        <FormikSelectField id="gender" name="gender" label={`${$t("Sex")} *`}>
                                            <option value="MALE">{$t("Male")}</option>
                                            <option value="FEMALE">{$t("Female")}</option>
                                        </FormikSelectField>
                                    </div>

                                    <InputWrapper>
                                        <FormikInputField id="firstName" fieldName="firstName" label={`${$t("First Name")} *`}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="middleName" fieldName="middleName" label={$t("insertion")}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="lastName" fieldName="lastName" label={`${$t("Last Name")} *`}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="birthDate" fieldName="birthDate" type="date" label={$t("Birthdate")}/>
                                    </InputWrapper>

                                    <InputWrapper>
                                        <FormikInputField id="phoneNumber" fieldName="phoneNumber" type="tel" label={$t("Telephone number")}/>
                                    </InputWrapper>

                                    <div className="mt-5 flex flex-wrap gap-2 px-2 lg:gap-0">
                                        <Link href="/my-account/profile">
                                            <PrimaryButton className="!bg-gray-shuttle !text-white hover:bg-auto" onClickFunction={handleCancel}>
                                                {$t("Cancel")}
                                            </PrimaryButton>
                                        </Link>
                                        <PrimaryButton className="!bg-black !text-white hover:bg-auto" buttonType="submit" disabled={isBtnSubmitting}>
                                            {$t("Update")}
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </div>
                        </FormikFormWrapper>
                    </Form>)}
            </Formik>
        </Section>);
};
export default observer(UpdateProfile);
