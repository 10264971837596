import { gtmAddToCartFromWishlist } from "@jog/shared";
import { useSession } from "next-auth/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAddedProduct } from "./useAddedProduct";
export var ProductStatus;
(function (ProductStatus) {
    ProductStatus[ProductStatus["SoldOut"] = 0] = "SoldOut";
    ProductStatus[ProductStatus["Unavailable"] = 1] = "Unavailable";
    ProductStatus[ProductStatus["Available"] = 2] = "Available";
})(ProductStatus || (ProductStatus = {}));
export const useWishlistItem = (pdpData, setSkuForPopup, cartStore, removeProductFromWishlist) => {
    const name = useMemo(() => {
        const rawName = pdpData.productName[process.env.NEXT_PUBLIC_LOCALE] || pdpData.productName.en;
        return rawName.slice(0, 1).toUpperCase() + rawName.slice(1);
    }, [pdpData.productName]);
    const [popupShow, setPopupShow] = useState(false);
    const [sku, setSku] = useState("");
    const [size, setSize] = useState("");
    const [fasletAvailable, setFasletAvailable] = useState(false);
    const productStatus = useMemo(() => {
        if (pdpData.published && !pdpData.purchasable && pdpData.sizes)
            return ProductStatus.SoldOut;
        if ((!pdpData.purchasable && !pdpData.sizes) || !pdpData.published)
            return ProductStatus.Unavailable;
        return ProductStatus.Available;
    }, [pdpData]);
    useEffect(() => {
        setFasletAvailable(false);
    }, [pdpData.id]);
    const hasDiscount = pdpData.discountedPrice && pdpData.discountedPrice < ((pdpData === null || pdpData === void 0 ? void 0 : pdpData.originalPrice) || 0);
    const session = useSession();
    const isLoggedIn = useMemo(() => session.status === "authenticated", [session.status]);
    const { addedProduct } = useAddedProduct();
    const addProductToCart = useCallback(async () => {
        if (!cartStore.isCtaDisabled) {
            setSkuForPopup(sku);
            await cartStore.addProductToCart(isLoggedIn, sku);
            setSize("");
            // Google Tag Manager code
            gtmAddToCartFromWishlist([
                {
                    id: pdpData.productId,
                    name: pdpData.productName[process.env.NEXT_PUBLIC_LOCALE] || pdpData.productName.en,
                    price: pdpData.discountedPrice || pdpData.originalPrice || 0,
                },
            ]);
            setPopupShow(false);
            addedProduct(sku);
            // remove from wishlist
            await removeProductFromWishlist(pdpData.id);
        }
    }, [addedProduct, cartStore, isLoggedIn, pdpData, removeProductFromWishlist, setSkuForPopup, sku]);
    return {
        name,
        popupShow,
        sku,
        size,
        fasletAvailable,
        productStatus,
        hasDiscount,
        addProductToCart,
        setPopupShow,
        setSize,
        setSku,
        setFasletAvailable,
    };
};
