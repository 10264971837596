import { Link, NextImage, useI18n, useLineItemLocalized, useLineItemPrice, useQuantity, } from "@jog/react-components";
import { gtmAdpageRemoveFromCart } from "@jog/shared";
import { useTheme } from "@jog/theming";
import { isFilled } from "@prismicio/helpers";
import classNames from "classnames";
import { GRichText, Price } from "components";
import { observer } from "mobx-react";
import { useStores } from "stores";
import RemoveItem from "../../../../public/svgAsComponent/remove-item.svg";
const buttonClass = "h-9 w-7.5 cursor-pointer border border-gray-main bg-white text-center align-middle text-22 text-black focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-bg";
const InputQuantity = observer(({ data, disabled }) => {
    const { cartStore } = useStores();
    const { add, sub } = useQuantity({
        data,
        disabled,
        cartStore,
    });
    return (<div className="mt-4 ml-0 flex md:ml-7.5">
            <button {...sub} className={classNames(buttonClass, "border-r-0")}>
                -
            </button>
            <input className="h-9 w-10 cursor-not-allowed border border-gray-main bg-white py-2.75 px-3 text-center text-sm text-black disabled:border-gray-main disabled:text-black disabled:opacity-100" disabled type="text" name="quantity" value={data.quantity}/>
            <button {...add} className={classNames(buttonClass, "border-l-0")}>
                +
            </button>
        </div>);
});
const PriceContainer = ({ data }) => {
    const theme = useTheme();
    const { finalPrice, firstPrice } = useLineItemPrice(data);
    if (finalPrice) {
        return (<div className="flex flex-col gap-2">
                <Price color="#444a55" fontSize="14px" className="font-normal" isOldPrice price={firstPrice}/>
                <Price color={theme.color.mainRed} fontSize="14px" price={finalPrice}/>
            </div>);
    }
    return <Price fontSize="14px" price={firstPrice}/>;
};
const Property = ({ label, children }) => {
    return (<div className="text-center font-primary text-13 font-medium lg:text-base">
            <p className="mb-2.5 font-primary font-normal capitalize text-black">{label}</p>
            {children}
        </div>);
};
export const CartItem = ({ data, disabled, quantityIsChanged }) => {
    const { gtmStore, cartStore } = useStores();
    const isInStock = data.availability.isOnStock;
    const { link, name, promotion } = useLineItemLocalized(data);
    const { $t } = useI18n();
    const deleteProduct = async () => {
        if (disabled) {
            cartStore.removeActionList(data.id);
        }
        else {
            await cartStore.deleteCartProduct(data.id, 100);
            gtmStore.gtmRemoveFromCart(data, data.quantity);
            gtmAdpageRemoveFromCart(data, data.quantity);
        }
    };
    return (<div className="w-full border-b border-b-gray-lightSand py-5 px-0 last:border-b-0">
            <div className={classNames("grid grid-cols-12", { "select-none opacity-50": disabled })}>
                <div className="col-span-4 lg:col-span-2">
                    <Link href={link} title={name} className={classNames("block w-full", { "pointer-events-none": disabled })}>
                        <NextImage blur={false} className="w-full" height={225} width={150} src={data.image} alt="Cart item image"/>
                    </Link>
                </div>
                <div className="col-span-8 flex flex-col justify-between pl-4 lg:col-span-10">
                    <div className="grid flex-1 grid-cols-1">
                        <div className="flex items-start justify-between">
                            <Link to={link} title={name} className={classNames("mb-2.5", { "pointer-events-none": disabled })}>
                                <h6 className="text-13 font-medium uppercase lg:text-base">{name}</h6>
                            </Link>
                            <button onClick={() => deleteProduct()} className="background-none flex cursor-pointer items-center border-none text-13 text-gray-main outline-none">
                                <RemoveItem alt="remove item" className="mr-1.25 h-3.75 w-5"/>
                            </button>
                        </div>

                        <div className="flex">
                            <PriceContainer data={data}/>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-12 md:col-span-4">
                                <div className="mt-2.5 w-full text-13 uppercase lg:mb-2.5 lg:text-xs">
                                    {isInStock ? (<span className="text-gray-dark">{$t("In stock")}</span>) : (<span className="text-red-mona">{$t("Out of stock")}</span>)}
                                </div>
                            </div>
                            <div className="col-span-3">
                                <Property label={$t("Size")}>{data.size}</Property>
                            </div>
                            {data.length && (<div className="col-span-3">
                                    <Property label={$t("Length")}>{data.length}</Property>
                                </div>)}
                            {quantityIsChanged && (<div className="col-span-12">
                                    <div className="my-1 font-primary text-13 italic text-red-main">
                                        {$t("Item is with changed quantity")}
                                    </div>
                                </div>)}
                            {promotion && (<div className="col-span-12">
                                    <div className="mb-0.75 font-primary text-13 font-medium text-red-main">
                                        {promotion}
                                    </div>
                                </div>)}
                            <div className="col-span-12 grid grid-cols-12">
                                <div className="col-span-12 md:col-span-8 md:col-start-5">
                                    <InputQuantity data={data} disabled={disabled}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 flex w-full flex-wrap justify-end">
                        <Price fontSize="18px" price={data.prices.totalPrice.centAmount}/>
                    </div>
                </div>
            </div>
            {isFilled.richText(data.alertInfo) && (<div className="mt-2.5 p-2.5" style={{ color: data.alertColor, backgroundColor: data.alertBgColor }}>
                    <GRichText paragraphClass="!mb-0 text-sm" field={data.alertInfo}/>
                </div>)}
        </div>);
};
